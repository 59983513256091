import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: false,
        themes: {
            light: {
                // primary: '#1976D2',     // Primary color
                primary: '#3273DC',     // Primary color
                secondary: '#00B0FF',   // Secondary color
                accent: '#82B1FF',      // Accented color
                error: '#FF5252',       // Error color
                info: '#2196F3',        // Info color
                success: '#4CAF50',     // Success color
                warning: '#FFC107',     // Warning color
                anchor: '#1976D2',      // Link color
            },
        },
    },
});
