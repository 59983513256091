import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

import Monitoring from '@/classes/Monitoring'

import { SITE_STATUS } from '@/constants/common'

class Charts {

// History chart functions

    // createHistChart (ref, items, field, title, warn, alert, tooltip) {
    createHistChart (ref, items, field, title, warn, tooltip) {
        let chart = am4core.create(ref, am4charts.XYChart);

        chart.paddingRight = 20;
        chart.dateFormatter.inputDateFormat = 'i';
        chart.dateFormatter.dateFormat = "I";
        chart.data = items;
        chart.fontSize = 11;
    
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        // dateAxis.renderer.labels.template.fontSize = 11;
    
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;
        if (items.length == 0) valueAxis.max = 10;
        valueAxis.extraMax = 0.2;
        valueAxis.title.text = title;
        // valueAxis.renderer.labels.template.fontSize = 11;
        // valueAxis.renderer.minWidth = 35;
    
        let warnRange = valueAxis.axisRanges.create();
        warnRange.value = warn;
        warnRange.grid.stroke = am4core.color(Monitoring.colorByStatus(SITE_STATUS.WARNING, true));
        warnRange.grid.strokeWidth = 1;
        warnRange.grid.strokeOpacity = 0.8;
        warnRange.grid.strokeLinecap = 'round';
        warnRange.grid.strokeDasharray = '4';
        warnRange.label.inside = true;
        warnRange.label.text = "Warning";
        warnRange.label.fill = warnRange.grid.stroke;
        //range.label.align = "right";
        warnRange.label.verticalCenter = "bottom";
    
        // let alertRange = valueAxis.axisRanges.create();
        // alertRange.value = alert;
        // alertRange.grid.stroke = am4core.color(Monitoring.colorByStatus(SITE_STATUS.ERROR, true));
        // alertRange.grid.strokeWidth = 1;
        // alertRange.grid.strokeOpacity = 0.8;
        // alertRange.grid.strokeLinecap = 'round';
        // alertRange.grid.strokeDasharray = '4';
        // alertRange.label.inside = true;
        // alertRange.label.text = "Alert";
        // alertRange.label.fill = alertRange.grid.stroke;
        // alertRange.label.verticalCenter = "bottom";
    
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "when";
        series.dataFields.valueY = field;
        series.strokeOpacity = 0;
    
        var bullet = series.bullets.push(new am4core.Circle());
        bullet.fill = am4core.color("#ddd");
        bullet.propertyFields.fill = "color";
        bullet.strokeOpacity = 0;
        // bullet.strokeOpacity = 1;
        bullet.radius = 5;
        bullet.strokeWidth = 2;
        // bullet.fillOpacity = 0.7;
        bullet.fillOpacity = 1;
        bullet.fontSize = 11;
        bullet.stroke = am4core.color("#ffffff");
    
        bullet.tooltipText = tooltip;
    
        var hoverState = bullet.states.create("hover");
        // hoverState.properties.fillOpacity = 1;
        hoverState.properties.strokeOpacity = 1;
    
        // series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });
    
        // series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();
    
        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;
    
        // return [chart, warnRange, alertRange, valueAxis];
        return [chart, warnRange, valueAxis];
    }

    destroyHistChart (chart) {
        if (chart) chart.dispose();
    }


// Dashboard chart functions

    getSitesStatusSeries (stats) {
        let result = [stats.status.unknown, stats.status.ok, stats.status.warning, stats.status.error];
        return result;
    }

    getSitesPingSeries (stats) {
        let result = [stats.ping.unknown, stats.ping.ok, stats.ping.warning, stats.ping.error];
        return result;
    }

    getSitesSslSeries (stats) {
        let result = [stats.ssl.unknown, stats.ssl.ok, stats.ssl.warning, stats.ssl.error];
        return result;
    }

    getSitesDomainSeries (stats) {
        let result = [stats.domain.unknown, stats.domain.ok, stats.domain.warning, stats.domain.error];
        return result;
    }

    getPagesStatusSeries (stats) {
        let result = [stats.pages.unknown, stats.pages.ok, stats.pages.warning, stats.pages.error];
        return result;
    }

    getPagesHtmlSeries (stats) {
        let result = [stats.html.unknown, stats.html.ok, stats.html.warning, stats.html.error];
        return result;
    }

    getPagesMobileSeries (stats) {
        let result = [stats.mobile.unknown, stats.mobile.ok, stats.mobile.warning, stats.mobile.error];
        return result;
    }

    getPagesDesktopSeries (stats) {
        let result = [stats.desktop.unknown, stats.desktop.ok, stats.desktop.warning, stats.desktop.error];
        return result;
    }


}


export default new Charts();