import axios from 'axios';

import Interface from '@/classes/Interface';
import System from '@/classes/System'
import ApiError from '@/classes/ApiError'

import Settings from '@/constants/settings'

class Public {

    constructor () {
        this.baseURL = Settings.pubBaseUrl;
    }

    // External public HTML wrapper
    html (url, _settings = {}) {
        return this.request('get', url, false, false, 'text', _settings);
    }

    // Generic request fnction
    request (method, url, params, data, type, _settings) {
        let config = {
            baseURL: this.baseURL,
            url: url,
            method: method,
            responseType: type,
        }

        let settings = {
            doLoading: true,
        }

        Object.assign(settings, _settings);
        System.debug(`Request [${config.method}][${config.url}]`);

        if (data) {
            config.data = data;
            System.debug('Data: ', config.data);
        }

        if (params) {
            config.params = params;
            System.debug('Params: ', config.params);
        }

        if (settings.doLoading) Interface.loadingOn();

        return new Promise ((resolve, reject) => {
            axios(config)
                .then(response => {
                    System.debug(`Response [${response.status}][${response.statusText}]`);
                    System.debug('Data: ', response.data);
                    resolve(response.data || '');
                })
                .catch(error => {
                    if (error.response) {
                        System.debug(`Server error [${error.response.status}][${error.response.statusText}]`);
                        reject(new ApiError(error.response.status, error.response.statusText || 'Unknown server error'));
                    } else if (error.request) {
                        System.debug('Network error');
                        reject(new ApiError(2, 'Network error'));
                    } else {
                        System.debug('Other error');
                        reject(new ApiError(3, error.message || 'Unknown error'));
                    }                
                })
                .finally(() => {
                    if (settings.doLoading) Interface.loadingOff();
                });
        })
    }

}


export default new Public();