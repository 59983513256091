<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">Reports</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Performance, SEO and HTML validation reports for your websites</p>
        </div>
        <div class="text-center pt-4 pb-2">
            <v-icon color="grey" large>mdi-dna</v-icon>
            <p class="my-0 grey--text text-overline">Under construction... coming soon...</p>
        </div>

    </v-sheet>
</div>
</template>


<script>
import Interface from "@/classes/Interface";

export default {
    name: 'Reports',
    data () {
        return {
        }
    },
    methods: {
        init () {
            Interface.alertOff();
        },
    },
    mounted () {
        this.init();
    },
}
</script>


<style scoped>
</style>
