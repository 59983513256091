<template>
<v-sheet color="grey lighten-4" class="d-flex pa-3">
    <v-avatar class="mr-4" :color="user.color" size="45">
        <img v-if="user.image" :src="user.image" :alt="user.name">
        <span v-else class="white--text ">{{ initials }}</span>
    </v-avatar>
    <div>
        <p class="text-subtitle-2 mb-0" :style="{color: system.settings.userNameColor}">{{ user.name }}</p>
        <p class="text-caption mb-0" :style="{color: system.settings.userNameColor}">{{ user.email }}</p>
        <!-- <p class="text-subtitle-2 mb-0 primary--text">Personal <span class="red--text">(upgrade to Pro)</span></p> -->
        <!-- <p class="text-subtitle-2 mb-0" :style="{color: system.settings.systemNameColor}">{{ system.name }}</p> -->
    </div>
</v-sheet>
</template>


<script>
// import { mapState } from 'vuex'

import User from '@/classes/User'

export default {
    name: 'Head',
    data () {
        return {
        }
    },
    computed: {
        user () {
            return this.$store.state.user.data;
        },
        system () {
            return this.$store.state.system;
        },
        initials () {
            return User.initials(this.user.name);
        }
    },

}
</script>


<style scoped>
</style>
