import store from '@/store'
import { SITE_STATUS } from '@/constants/common'

// import Api from '@/services/api'
// import Api from '@/services/api-mock'
import Api from '@/services/api-wrapper'

class Sites {

    // constructor () {
    //     User.addRefreshFn(this.get);
    // }

// Data functions

    setSites(sites) {
        store.commit('user/setSites', sites);
    }

    updateSites(sites) {
        sites.forEach(site => {
            store.commit('user/updateSite', site);
        });
    }

// Helper functions 

    computeSiteData(site) {
        site.status = Math.max(site.statusPing, site.statusSsl, site.statusDomain);
        site.statusPages = SITE_STATUS.UNKNOWN;
        site.countPages = site.pages.length || 0;
        
        site.pages.forEach(page => {
            this.computePageData(site, page);
            site.statusPages = Math.max(page.status, site.statusPages);
        });
        
        site.statusGroup = Math.max(site.status, site.statusPages) || SITE_STATUS.UNKNOWN;
        
        return site;
    }

    computePageData(site, page) {
        page.urlFull = site.url + page.url;
        page.siteID = site.id;
        page.status = Math.max(page.statusUptime, page.statusHtml, page.perfMobile, page.perfDesktop) || SITE_STATUS.UNKNOWN;

        return page;
    }

    updateSiteEntry(sites, data) {
        let result = false;

        sites.forEach(site => {
            if (site.id == data.id) {
                Object.assign(site, data);
                result = site;
            }
        });

        if (!result) {
            sites.push(data);
            result = data;
        }

        return result;
    }

    deleteSiteEntry(sites, id) {
        sites.forEach((site, index) => {
            if (site.id == id) {
                sites.splice(index, 1);
            }
        });
    }

    getSiteEntry(sites, id) {
        let result = false;

        sites.forEach(site => {
            if (site.id == id) {
                result = site;
            }
        });
        
        return result;
    }

    getPageEntry(site, id) {
        let result = false;

        site.pages.forEach(page => {
            if (page.id == id) {
                result = page;
            }
        });
        
        return result;
    }

    getPageEntryGlobal(sites, id) {
        let result = false;

        sites.forEach(site => {
            site.pages.forEach(page => {
                if (page.id == id) {
                    result = page;
                }
            });
        });
        
        return result;
    }

    pageUrl (url) {
        if (url == '/' || url == '')
            return '/ (index page)';
        else
            return url;
    }

    isHttps (url) {
        return url.startsWith('https://');
    }

// API functions 

    get () {
        return Api.post('/secure/sites/getlist', false, false, {doLoading: false})
            .then(data => {
                this.setSites(data);
                return data;
            });
    }

    addURL (data) {
        return Api.post('/secure/sites/addurl', data)
            .then(data => {
                data.opened = true;
                store.commit('user/updateSite', data.site);
                return data;
            });
    }

    deleteSite (id) {
        return Api.post('/secure/sites/delsite', {id})
            .then(data => {
                store.commit('user/deleteSite', id);
                return data;
            });
    }

    deletePage (id) {
        return Api.post('/secure/sites/delpage', {id})
            .then(data => {
                store.commit('user/updateSite', data);
                return data;
            });
    }

}


export default new Sites();