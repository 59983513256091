import router from '@/router'

import Sites from '@/classes/Sites'

function getDefaultState () {
    return {
        data: {
            email: undefined,
            name: undefined,
            image: undefined,
            color: '#757575',
            plan: undefined,
        },
        session: {
            type: 0,
        },
        settings: {},
        sites: [],
    }
}

const state = getDefaultState();

const getters = {};

const mutations = {
    resetState (state) {
        state.sites = [];
        Object.assign(state, getDefaultState());
    },
    setData (state, data) {
        Object.assign(state.data, data);
    },
    setSettings (state, settings) {
        Object.assign(state.settings, settings);
    },
    setSession (state, session) {
        state.session.type = session.type;
    },
    setSites (state, sites) {
        sites.forEach(site => {
            Sites.computeSiteData(site);
        });
        state.sites = sites;
    },
    updateSite (state, site) {
        Sites.computeSiteData(site);
        Sites.updateSiteEntry(state.sites, site);
    },
    deleteSite (state, id) {
        Sites.deleteSiteEntry(state.sites, id);
    },
};

const actions = {
    logout (context) {
        context.commit('logout');
        router.push('login');
    },
};

// GET_TODO: async (context, payload) => {
//     let {data} = await Axios.get('http://yourwebsite.com/api/todo');
//     context.commit('SET_TODO', data);
//   },

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }