<template>
<div>
    <Monitor
        :iid="page.id"
        label="Uptime Monitor"
        offq="Do you want to turn off page uptime monitoring?"
        code="uptime"
        :options="optionsForm"
        :form="$refs.optionsForm"
        v-on:enabled="$emit('enabled', $event)"
        v-on:status="$emit('status', $event)"
        v-on:init="$emit('init')"
        v-on:options="resetOptions($event)"
        v-on:histadd="addItems = $event"
        v-on:histnew="newItems = $event"
    >
        <template v-slot:details="{monitor}">
            <p class="text-caption mb-0">
                <span class="font-weight-medium mr-2">Last response code:</span>
                <span :class="httpColor(monitor.data.lastResponseCode)+'--text'">HTTP {{monitor.data.lastResponseCode}}</span>
            </p>
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Last load time:</span>{{monitor.data.lastLoadTime}} sec</p>
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Last content size:</span>{{monitor.data.lastContentSize}} bytes</p>
        </template>

        <template v-slot:chart>
            <ChartUptime :warning="warning" :items="newItems" :add="addItems" />
        </template>

        <template v-slot:options>
            <v-form ref="optionsForm">
                <v-subheader class="pl-0">Check frequency:</v-subheader>
                <v-slider
                    v-model="interval"
                    :max="intervalItems.length - 1"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    prepend-icon="mdi-timer"
                    :messages="intervalItems[interval] + planWarning"
                    :rules="[
                        (v) => validMinInt(v) || 'Interval error',
                    ]"
                ></v-slider>
                <v-subheader class="pl-0 mt-6">Notification frequency:</v-subheader>
                <v-select
                    v-model="frequency"
                    :items="frequencyItems"
                    item-text="label"
                    item-value="frequency"
                    dense
                    prepend-icon="mdi-bell-outline"
                ></v-select>
                <v-subheader class="pl-0 mt-6">Page load time warning:</v-subheader>
                <v-slider
                    v-model="warning"
                    min="0.5"
                    max="10"
                    step="0.5"
                    :ticks="true"
                    tick-size="1"
                    prepend-icon="mdi-alert-outline"
                    :messages="`If more than ${warning} seconds`"
                ></v-slider>
                <v-subheader class="pl-0 mt-6">Allowed redirects:</v-subheader>
                <v-slider
                    v-model="redirects"
                    min="0"
                    max="10"
                    step="1"
                    :ticks="true"
                    tick-size="1"
                    prepend-icon="mdi-directions"
                    :messages="msgRedirects"
                ></v-slider>
                <v-subheader class="pl-0 mt-6">Minimum content size:</v-subheader>
                <v-text-field
                    v-model="size"
                    type="number"
                    min="0"
                    max="9999999999"
                    required
                    prepend-icon="mdi-subtitles"
                    suffix="bytes"
                    :rules="[
                        (v) => Number.isInteger(+v) || 'Enter minimum content length or 0',
                        (v) => v >= 0  || 'Must be a positive number',
                        (v) => v < 9999999999  || 'Number too big',
                    ]"
                    style="width: 250px"
                ></v-text-field>
            </v-form>
        </template>

        <template v-slot:help>
            <p class="text-body-1 text-center">Uptime Monitor</p>
            <p>
                Your web server at your hosting provider may be up and running, but nevertheless some of your pages still may fail to be displayed correctly. 
                Web server may display an empty page, some error message or page may take too long time to load. In all these cases, users visiting your website will suffer and may have a bad experience.
            </p>
            <p>
                Our Page Uptime Monitor checks availability of the web page. Monitor checks the HTTP response code received, measures page loading time and content size, and alerts if any of those measures go below the configured threshold.
            </p>
            <p>
                Monitor can be controlled with following settings:
            </p>
            <p class="mb-0 font-weight-bold">Check frequency</p>
            <p>
                How often shall we check page availability.
            </p>
            <p class="mb-0 font-weight-bold">Notification frequency</p>
            <p>
                How often shall we send you alerts about discovered problems.
                <ul>
                    <li>Every check - We will send you notification every time we discover a problem even if this problem was already reported after the previous check.</li>
                    <li>Once per problem - We will send you notification once when a problem is first discovered or problem severity increases compared to the previous check.</li>
                    <li>Once in an hour / Once in a day - Notifications about the same problem will be sent no more often than the selected period. New notification may be sent if problem severity increases compared to the previous check.</li>
                    <li>Don’t send notifications - We will not send any notifications about this event.</li>
                </ul>
            </p>
            <p class="mb-0 font-weight-bold">Page load time warning</p>
            <p>
                We will send you a warning if page load time exceeds this threshold. We measure only main file load time, any attached scripts or images are not measured. To see full page load time you can use our performance monitors.
            </p>
            <p class="mb-0 font-weight-bold">Allowed redirects</p>
            <p>
                Sometimes a web server is configured to redirect a user from the page he has requested to some other page. It may be needed to redirect users to a new version of the page or the same page, but in another language. But sometimes it can mean that a web page is hacked and your users are redirected to some malicious phishing site. 
                Also due to web server misconfiguration users may get into a chain of redirects and the browser will abort the connection displaying an error to the user. We will send you a warning when the number of redirects on your page exceeds the configured threshold. If your page does not use redirects, then we recommend setting it to “not allowed”.
            </p>
            <p class="mb-0 font-weight-bold">Minimum content size</p>
            <p>
                It is useful to monitor received content to detect situations when a web server displays an empty page or some error message instead of the correct content. Usually, when this happens, the content size of such page is much smaller than the content size of the full correct page.
                We will send you a warning when the content size of your page is below this  threshold, so you are alerted if the proper content of your page is not displayed. We measure only the size of the main file, all attached scripts and images are not measured.
            </p>
        </template>

    </Monitor>
</div>
</template>


<script>
import {mapState} from 'vuex'

import Monitoring from '@/classes/Monitoring'
import Dates from '@/classes/Dates'

import Monitor from '@/components/monitoring/Monitor'
import System from '@/classes/System'
import ChartUptime from '@/components/charts/ChartUptime'


export default {
    name: 'MonitorUptime',
    props: ['page'],
    components: {
        Monitor,
        ChartUptime,
    },
    data () {
        return {
            // Options
            interval: 3,
            intervalItems: [
                'Every minute',
                'Every 5 minutes',
                'Every 15 minutes',
                'Every 30 minutes',
                'Every hour',
                'Every 4 hours',
                'Every 12 hours',
                'Once a day',
            ],
            intervals: {
                0:       1 * 60,
                1:       5 * 60,
                2:      15 * 60,
                3:      30 * 60,
                4:      60 * 60,
                5:  4 * 60 * 60,
                6: 12 * 60 * 60,
                7: 24 * 60 * 60,
            },
            frequency: 2,   
            frequencyItems: [
                {frequency: 1, label: "Every check"},
                {frequency: 2, label: "Once per problem"},
                {frequency: 3, label: "Once in an hour"},
                {frequency: 4, label: "Once in a day"},
                {frequency: 0, label: "Don't send notifications"},
            ],
            size: 0,
            warning: 0,
            redirects: 0,
            plan: false,
            // Chart
            newItems: [],
            addItems: [],
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        optionsForm () {
            return {
                intervalCheck: this.intervals[this.interval],
                alertFreq: this.frequency,
                minContentSize: this.size,
                warnLoadTime: this.warning,
                numRedirects: this.redirects,
            }
        },
        msgRedirects () {
            if (this.redirects == 0)
                return `Redirects are not allowed`;
            else
                return `No more than ${this.redirects}`;
        },
        planWarning () {
            if (this.plan && this.intervals[this.interval] < this.plan.limits.minInt) {
                return ' (not available in your subscription plan)'
            } else {
                return ''
            }
        },
    },
    methods: {
        init () {
            this.plan = System.getPlan(this.user.data.plan);
        },
        resetOptions (options) {
            this.interval = Object.keys(this.intervals).find(key => this.intervals[key] == options.intervalCheck) || 3;
            this.frequency = options.alertFreq;
            this.size = options.minContentSize;
            this.warning = options.warnLoadTime;
            this.redirects = options.numRedirects;
        },
        humandate (date) {
            return Dates.human(date);
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        httpColor (value) {
            return Monitoring.httpColor(value);
        },
        validMinInt (v) {
            return this.intervals[v] >= this.plan.limits.minInt;
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        'page': 'init',
    },    
}
</script>


<style scoped>
</style>
