<template>
    <v-snackbar v-model="showSnack" :timeout="timeout" :color="color">
        <v-icon class="mr-3">{{ icon }}</v-icon>{{ snack.text }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="showSnack = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>


<script>
import { SNACK_TYPE } from '@/constants/interface'

export default {
    name: 'Snack',
    data () {
        return {
            timeout: 5000,
        }
    },
    computed: {
        color () {
            if (this.snack.type == SNACK_TYPE.SUCCESS) {
                return 'success'
            } else if (this.snack.type == SNACK_TYPE.WARNING) {
                return 'warning'
            } else if (this.snack.type == SNACK_TYPE.ERROR) {
                return 'error'
            } else {
                return 'info'
            }
        },
        icon () {
            if (this.snack.type == SNACK_TYPE.SUCCESS) {
                return 'mdi-checkbox-marked-circle-outline'
            } else if (this.snack.type == SNACK_TYPE.WARNING) {
                return 'mdi-alert-outline'
            } else if (this.snack.type == SNACK_TYPE.ERROR) {
                return 'mdi-alert'
            } else {
                return 'mdi-information-outline'
            }
        },
        snack () {
            return this.$store.state.system.snack;
        },
        showSnack: {
            get () {
                return this.snack.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/snackOff', false)
                }
            }
        },
    },
}
</script>


<style scoped>
</style>
