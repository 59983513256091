<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">Support</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Service support page</p>
        </div>
        <div class="pt-2">

            <v-tabs v-model="tabs">
                <v-tab>Support</v-tab>
                <v-tab>Terms and Privacy</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" class="pt-6">
                <v-tab-item>
                    <v-row justify="center">
                        <v-col cols="12" sm="8" class="text-center pb-7">
                            <v-icon x-large color="grey">mdi-face-agent</v-icon>
                            <p class="mt-5">All support requests please send to email:</p>
                            <p class="mt-2 mb-10"><a :href="'mailto:'+email">{{email}}</a></p>
                            <v-btn color="primary" @click="copyEmail()" width="150">Copy Email</v-btn>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <div v-html="termsData"></div>
                </v-tab-item>
            </v-tabs-items>                

        </div>

    </v-sheet>


</div>
</template>


<script>
import Interface from "@/classes/Interface";
import System from '@/classes/System'
import Settings from '@/constants/settings'
import Info from '@/classes/Info'

export default {
    name: 'Support',
    data () {
        return {
            email: '',
            tabs: undefined,
            termsData: 'Loading...',
        }
    },
    methods: {
        init () {
            Interface.alertOff();
            this.email = Settings.supportEmail;
            this.fetchInfo();
        },
        fetchInfo () {
            Interface.alertOff();

            Promise.all([
                Info.getTerms(),
            ]).then(data => {
                this.termsData = data[0];
            })
            .catch((error) => {
                System.apiDecline(error, Interface.alertError);
            });
        },
        copyEmail () {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.email)
                    .then(() => {
                        Interface.snackSuccess('Email copied to clipboard');
                    })
                    .catch(() => {
                        Interface.snackError('Failed to copy email');
                    });
            } else {
                Interface.snackError('Failed to copy email');
            }
        },
        // planErr () {
        //     this.$store.commit('system/setPlanError', true);
        // },
    },
    mounted () {
        this.init();
    },
}
</script>


<style scoped>
</style>
