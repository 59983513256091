class Dates {

// Helper functions

    human (date, format = 1) {
        let dt = new Date(date);
        
        switch (format) {
            // '24 Nov 2021, 14:26'
            case 1: return dt.toLocaleString('en-GB',{month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', hour12: false, minute: '2-digit'});
            // '14:26'
            case 2: return dt.toLocaleString('en-GB',{hour: '2-digit', hour12: false, minute: '2-digit'});
            // '24 Nov 2021'
            case 3: return dt.toLocaleString('en-GB',{month: 'short', day: 'numeric', year: 'numeric'});
            // default
            default: return '';
        }
    }

// Date functions

    formatter (dt, format = 1) {
        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        switch (format) {
            case 1: return dt.toJSON().substring(0,10); // YYYY-MM-DD
            case 2: return dt.toJSON();                 // YYYY-MM-DDTHH:MM:SS.000Z
            default: return '';
        }
    }

    today (format = 1) {
        let dt = new Date();
        return this.formatter(dt, format);
    }

    daystart (date, format = 2) {
        let dt = new Date(date + 'T00:00:00.000Z');
        return this.formatter(dt, format);
    }
   
    dayend (date, format = 2) {
        let dt = new Date(date + 'T23:59:59.999Z');
        return this.formatter(dt, format);
    }
   
    daysAgo (days, format = 1) {
        let dt = new Date();
        dt.setDate(dt.getDate() - days);
        return this.formatter(dt, format);
    }

    // thisWeekStart () {
    //     let dt = new Date();
    //     let days = dt.getDay() - 1;
    //     if (days === -1) days = 6;
    //     dt.setDate(dt.getDate() - days);
    //     return this.strDateFormatter(dt);
    // }

    // thisMonthStart () {
    //     let dt = new Date();
    //     dt.setDate(1);
    //     return this.strDateFormatter(dt);
    // }
    
    // prevMonthStart () {
    //     let dt = new Date();
    //     dt.setDate(1);
    //     dt.setMonth(dt.getMonth() - 1);
    //     return this.strDateFormatter(dt);
    // }
    
    // prevMonthEnd () {
    //     let dt = new Date();
    //     dt.setDate(0);
    //     return this.strDateFormatter(dt);
    // }
    
}

export default new Dates();