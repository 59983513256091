<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="mb-3">
        
        <div class="d-flex align-center">
            <div class="ml-7">
                <p class="mt-4 grey--text text--darken-1"><v-icon color="grey" class="mr-5">mdi-file-outline</v-icon>Pages:</p>
            </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon static top right class="mr-3" v-bind="attrs" v-on="on" @click.stop="dialogHelp = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
                </template>    
                <span>Description</span>
            </v-tooltip>
        </div>

        <div class="px-5 pt-2 pb-5 mb-10">
            <v-simple-table class="grey lighten-5 rounded-0">
                <template v-slot:default>
                    <tbody>
                        <tr v-for="page in site.pages" :key="page.id" >
                            <td>
                                <router-link :to="{name: 'monitor-page', params: {id: page.id}}" class="teal--text text--darken-2">
                                    <code>{{pageUrl(page.url)}}</code>
                                </router-link>
                            </td>
                            <td style="width: 30%" class="text-center" nowrap>
                                <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.statusUptime)" v-bind="attrs" v-on="on" class="mr-4">mdi-file-document-outline</v-icon>
                                    </template>
                                    <span>Uptime Status: {{descr(page.statusUptime)}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.statusHtml)" v-bind="attrs" v-on="on" class="mr-4">mdi-file-code-outline</v-icon>
                                    </template>
                                    <span>HTML Status: {{descr(page.statusHtml)}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.perfMobile)" v-bind="attrs" v-on="on" class="mr-4">mdi-cellphone</v-icon>
                                    </template>
                                    <span>Mobile Performance: {{descr(page.perfMobile)}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.perfDesktop)" v-bind="attrs" v-on="on">mdi-monitor</v-icon>
                                    </template>
                                    <span>Desktop Performance: {{descr(page.perfDesktop)}}</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>

    </v-sheet>

    <v-dialog v-model="dialogHelp" scrollable max-width="800px">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="grey">mdi-help-circle-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <p class="text-body-1 text-center">Pages Monitor</p>
                    <p>
                        This widget displays the status of all added pages of this website. To add a new page, go to My Websites main screen and add the full URL of your page. 
                        Widget table displays status of following page level monitors: Page Uptime Monitor, HTML Validator, Mobile and Desktop Performance.
                    </p>
                    <p>
                        Click on each page to go to the page monitoring section to see the details.
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="dialogHelp = false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
   
</div>
</template>


<script>
import System from '@/classes/System'
import Monitoring from '@/classes/Monitoring'
import Sites from '@/classes/Sites'

export default {
    name: 'MonitorPages',
    props: ['site'],
    data () {
        return {
            dialogHelp: false,
        }
    },
    computed: {
    },
    methods: {
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        descr (status) {
            return Monitoring.descrByStatus(status);
        },
        navToSiteMon (id) {
            System.redirectTo({name: 'monitor-site', params: {id}});
        },
        navToPageMon (id) {
            System.redirectTo({name: 'monitor-page', params: {id}});
        },
        pageUrl (url) {
            return Sites.pageUrl(url);
        },
    },

}
</script>


<style scoped>
</style>
