<template>
<div class="pt-8">
    <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
            <v-alert text prominent type="error" icon="mdi-cloud-alert">
                <div class="mx-4">
                    {{ text }}
                </div>
                <div class="text-right mt-4 mr-4">
                    <v-btn color="red" @click="refresh()">Refresh</v-btn>
                </div>
            </v-alert>
        </v-col>
    </v-row>
</div>
</template>


<script>
export default {
    name: 'SystemError',
    props: ['text'],
    data () {
        return {
        }
    },
    methods: {
        refresh () {
            location.reload();
        },
    },
}
</script>


<style scoped>
</style>
