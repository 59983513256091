<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">Terms and Privacy</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Service terms and privacy policy</p>
        </div>
        <div class="pt-2">
            <div v-html="termsData"></div>
        </div>
        <div class="text-center mt-5">
            <v-btn text color="primary" @click.stop="$router.push({name: backAction})" style="width: 150px">Back</v-btn>
        </div>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon absolute top left v-bind="attrs" v-on="on" @click.stop="$router.push({name: backAction})"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>    
            <span>Go back</span>
        </v-tooltip>

    </v-sheet>


</div>
</template>


<script>
import Interface from "@/classes/Interface";
import System from '@/classes/System'
import Info from '@/classes/Info'

export default {
    name: 'Terms',
    data () {
        return {
            termsData: 'Loading...',
        }
    },
    computed: {
        backAction () {
            return this.$route.query.back || 'login';
        },
    },
    methods: {
        init () {
            Interface.alertOff();
            this.fetchInfo();
        },
        fetchInfo () {
            Interface.alertOff();

            Promise.all([
                Info.getTerms(),
            ]).then(data => {
                this.termsData = data[0];
            })
            .catch((error) => {
                System.apiDecline(error, Interface.alertError);
            });
        },
    },
    mounted () {
        this.init();
    },
}
</script>


<style scoped>
</style>
