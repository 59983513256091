<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">Event Log</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">View all your system events for selected date.</p>
        </div>

        <div>
            <v-expansion-panels hover :value="0">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>Search events:</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" md="6" class="text-center">
                                <v-date-picker
                                    v-model="eventDate"
                                    first-day-of-week="1"
                                    no-title
                                    :max="today"
                                    min="2010-01-01"
                                    :events="eventDates"
                                >
                                </v-date-picker>
                            </v-col>
                            <v-col class="mr-md-6">
                                <v-select
                                    :items="siteItems"
                                    item-value="id"
                                    item-text="url"
                                    label="Website"
                                    v-model="eventSite"
                                ></v-select>
                                <v-select
                                    :items="monitorItems"
                                    label="Monitor"
                                    v-model="eventMonitor"
                                ></v-select>
                                <v-select
                                    :items="severityItems"
                                    label="Severity"
                                    v-model="eventSeverity"
                                ></v-select>
                                <v-text-field 
                                    label="Search"
                                    type="text"
                                    v-model="eventSearch"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div class="mt-10">
            <div class="mb-2">
                <span class="ml-5 text-caption font-weight-bold grey--text text--darken-1">Date:</span>
                <span class="ml-3 text-body-2 info--text">{{humandate(eventDate)}}</span>
            </div>
            <v-data-table
                :headers="headers"
                :items="events"
                :search="eventSearch"
                :items-per-page="25"
                :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                show-expand
                mobile-breakpoint="0"
                no-data-text="No events for selected date..."
                no-results-text="No events selected..."
                :loading="eventLoading"
                loading-text="Loading events..."
            >
                <template v-slot:[`item.severity`]="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon :color="color(item.severity)" v-bind="attrs" v-on="on" class="mr-4">{{statusIcon(item.severity)}}</v-icon>
                        </template>
                        <span>{{statusDescr(item.severity)}}</span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.monitor`]="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon color="grey" v-bind="attrs" v-on="on" class="mr-4">{{monitorIcon(item.monitor)}}</v-icon>
                        </template>
                        <span>{{monitorDescr(item.monitor)}}</span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.when`]="{item}">
                    {{humantime(item.when)}}
                </template>

                <template v-slot:[`item.siteId`]="{item}">
                    <router-link :to="{name: 'monitor-site', params: {id: item.siteId}}">
                        {{site(item.siteId).url}}
                    </router-link>
                </template>

                <template v-slot:expanded-item="{item}">
                    <td :colspan="headers.length">
                        <div class="pa-2 text-caption">
                            <p class="mb-0">
                                <span class="mr-3 font-weight-medium">Site:</span>
                                <router-link :to="{name: 'monitor-site', params: {id: item.siteId}}">
                                    <span>{{site(item.siteId).url}}</span>
                                </router-link>
                            </p>
                            <p class="mb-0" v-if="item.pageId">
                                <span class="mr-3 font-weight-medium">Page:</span>
                                <router-link :to="{name: 'monitor-page', params: {id: item.pageId}}">
                                    <span>{{(page(item.pageId).url == '/' ? '/ (index page)' : page(item.pageId).url)}}</span>
                                </router-link>
                            </p>
                            <p class="mb-0">
                                <span class="mr-3 font-weight-medium">Monitor:</span>
                                <span>{{monitorDescr(item.monitor)}}</span>
                            </p>
                            <p class="mb-0">
                                <span class="mr-3 font-weight-medium">Notification:</span>
                                <span v-if="item.notif">Sent</span>
                                <span v-else>Skipped</span>
                            </p>
                        </div>
                    </td>
                </template>

            </v-data-table>
        </div>


    </v-sheet>

</div>
</template>


<script>
import {mapState} from 'vuex'

import {SITE_STATUS} from '@/constants/common'

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Sites from '@/classes/Sites'
import Events from '@/classes/Events'
import Monitoring from '@/classes/Monitoring'

export default {
    name: 'Events',
    data () {
        return {
            SITE_STATUS,
            events: [],
            eventLoading: false,
            eventDate: '',
            eventSite: 0,
            eventSearch: '',
            eventSeverity: 0,
            eventMonitor: 0,
            severityItems: [
                {value: 0, text: 'All events'},
                {value: 2, text: 'Only warnings'},
                {value: 3, text: 'Only errors'},
            ],
            monitorItems: [
                {value: 0, text: 'All monitors'},
                {value: 'domain', text: this.monitorDescr('domain')},
                {value: 'ssl', text: this.monitorDescr('ssl')},
                {value: 'ping', text: this.monitorDescr('ping')},
                {value: 'uptime', text: this.monitorDescr('uptime')},
                {value: 'html', text: this.monitorDescr('html')},
                {value: 'mobile', text: this.monitorDescr('mobile')},
                {value: 'desktop', text: this.monitorDescr('desktop')},
            ],
            headers: [
                {
                    text: 'Severity',
                    sortable: true,
                    filterable: true,
                    value: 'severity',
                    width: '100px',
                    align: 'center',
                    filter: this.filterSeverity,
                },
                {
                    text: 'Monitor',
                    sortable: true,
                    filterable: true,
                    value: 'monitor',
                    width: '100px',
                    align: 'center',
                    filter: this.filterMonitor,
                },
                {
                    text: 'Time',
                    sortable: true,
                    filterable: false,
                    value: 'when',
                    width: '100px',
                },
                {
                    text: 'Site',
                    sortable: true,
                    filterable: true,
                    value: 'siteId',
                    filter: this.filterSite,
                },
                {
                    text: 'Message',
                    sortable: false,
                    filterable: true,
                    value: 'message',
                },
                {
                    text: '',
                    value: 'data-table-expand',
                },
            ],
            datesWarning: [],
            datesError: [],
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        today () {
            return Dates.today();
        },
        siteItems () {
            let result = this.sites.slice();
            result.unshift({id: 0, url: 'All websites'});
            return result;
        },
    },
    methods: {
        fetchData (date) {
            this.eventLoading = true;
            this.events = [];

            Events.date(date)
                .then(data => {
                    this.events = data;
                })
                .catch(error => {
                    System.apiDecline(error, Interface.alertError);
                })
                .finally(() => {
                    this.eventLoading = false;
                });
        },
        fetchDates () {
            this.datesWarning = [];
            this.datesError = [];

            Promise.all([
                Events.dates(SITE_STATUS.WARNING),
                Events.dates(SITE_STATUS.ERROR),
            ]).then(data => {
                this.datesWarning = data[0];
                this.datesError = data[1];
            })
            .catch((error) => {
                System.processDecline(error);
            });
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        statusDescr (status) {
            return Monitoring.descrByStatus(status);
        },
        statusIcon (status) {
            return Monitoring.iconByStatus(status);
        },
        monitorIcon (monitor) {
            return Monitoring.iconByMonitor(monitor);
        },
        monitorDescr (monitor) {
            return Monitoring.descrByMonitor(monitor);
        },
        humantime (date) {
            return Dates.human(date, 2);
        },
        humandate (date) {
            return Dates.human(date, 3);
        },
        site (id) {
            return Sites.getSiteEntry(this.sites, id);
        },
        page (id) {
            return Sites.getPageEntryGlobal(this.sites, id);
        },
        filterSeverity (value) {
            return !this.eventSeverity || value == this.eventSeverity;
        },
        filterMonitor (value) {
            return !this.eventMonitor || value == this.eventMonitor;
        },
        filterSite (value) {
            return !this.eventSite || value == this.eventSite;
        },
        eventDates (date) {
            let result = [];
            if (this.datesWarning.includes(date)) result.push(this.color(SITE_STATUS.WARNING));
            if (this.datesError.includes(date)) result.push(this.color(SITE_STATUS.ERROR));
            return result;
        },
    },
    mounted () {
        Interface.alertOff();
        this.eventDate = this.today;
        this.fetchDates();
    },
    watch: {
        eventDate (newv) {
            if (newv) this.fetchData(newv);
        }
    },
}
</script>


<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #FAFAFA;
}

.input-box {
    width: 400px;
    min-width: 200px;
}
</style>
