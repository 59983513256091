<template>
<div>
    <router-link :to="{ name: 'home' }">
        <v-img
            alt="Logo"
            class="shrink mx-2"
            contain
            src="/images/logo.png"
            :transition="transition"
            :width="width"
            :height="height"
        />
    </router-link>
</div>
</template>


<script>
export default {
    name: 'Logo',
    props: ['width', 'height', 'transition'],
    data () {
        return {
        }
    },

}
</script>


<style scoped>
</style>
