<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="mb-3">
        
        <div class="d-flex align-center" v-if="monitor">
            <div class="ml-7">
                <v-switch v-model="onoffSwitch" @change="onoff()" :loading="onoffLoading" :disabled="onoffDisabled" :label="label"></v-switch>
            </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="monitor.enabled">
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon static top right v-bind="attrs" v-on="on" @click.stop="settings()"><v-icon>mdi-cog-outline</v-icon></v-btn>
                </template>    
                <span>Settings</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon static top right v-bind="attrs" v-on="on" @click.stop="refresh()" :loading="fetchLoading"><v-icon>mdi-refresh</v-icon></v-btn>
                </template>    
                <span>Refresh</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon static top right class="mr-3" v-bind="attrs" v-on="on" @click.stop="dialogHelp = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
                </template>    
                <span>Description</span>
            </v-tooltip>
        </div>
        <div v-else-if="error" class="text-center pt-4 pb-2">
            <v-icon color="error" large>mdi-alert</v-icon>
            <p class="my-0 error--text text-overline">Data not available</p>
            <v-tooltip left>
                <template v-slot:activator="{on, attrs}">
                    <v-btn color="grey" icon @click="refresh()" v-bind="attrs" v-on="on"><v-icon>mdi-refresh</v-icon></v-btn>
                </template>    
                <span>Refresh</span>
            </v-tooltip>
        </div>
        <div v-else class="text-center pa-5">
            <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </div>
        
        <v-expand-transition>
            <div v-if="monitor && monitor.enabled" class="px-6 pb-1">
                <div v-if="monitor.status == SITE_STATUS.UNKNOWN" class="pb-6 pt-2">
                    <v-icon color="grey" large class="mr-3">mdi-clock-outline</v-icon>
                    <span class="grey--text">Collecting initial data, this may take several minutes...</span>
                </div>
                <div v-else>
                    <div class="mb-5">
                        <v-icon :color="color(monitor.status)" large class="mr-3">{{icon}}</v-icon>
                        <span :class="color(monitor.status)+'--text'">{{monitor.message}}</span>
                    </div>
                    <slot 
                        name="details" 
                        v-bind:monitor="monitor"
                    ></slot>
                    <div v-if="hasChart">
                        <p class="text-caption mt-5">
                            <span class="font-weight-medium mr-2">History chart:</span>
                            <span v-if="!showChart">
                                <a href="#" @click.prevent="showChart = true">Show</a>
                            </span>
                            <span v-else>
                                <span><a href="#" @click.prevent="fetchLast()">Last records</a></span>
                                <span class="mx-2">|</span>
                                <v-menu
                                    v-model="menuCal"
                                    transition="slide-x-reverse-transition"
                                    absolute
                                    rounded
                                    :close-on-content-click="false"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{on, attrs}">
                                        <a href="#" @click.prevent="" v-bind="attrs" v-on="on">Select date</a>
                                    </template>

                                    <v-date-picker
                                        v-model="chartDate"
                                        first-day-of-week="1"
                                        scrollable
                                        no-title
                                        :max="today"
                                        :min="histLength"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="fetchDate(); menuCal = false">OK</v-btn>
                                        <v-btn text color="default" @click="menuCal = false">Cancel</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                                <span class="mx-2">|</span>
                                <span><a href="#" @click.prevent="showChart = false">Close</a></span>
                            </span>
                        </p>
                        <v-expand-transition>
                            <div v-if="showChart">
                                <slot 
                                    name="chart" 
                                    v-bind:monitor="monitor"
                                ></slot>
                            </div>
                        </v-expand-transition>
                    </div>
                    <p class="text-caption text--secondary mb-1 mt-4"><span class="mr-3">Last checked:</span>{{humandate(monitor.lastCheck)}}</p>
                </div>
            </div>
        </v-expand-transition>

    </v-sheet>

    <v-dialog v-model="dialogSettings" persistent scrollable max-width="500px" v-if="monitor">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="primary">mdi-cog-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <slot 
                        name="options" 
                        v-bind:monitor="monitor"
                    ></slot>
                </div>
                <div class="mt-8">
                    <a href="#" @click.stop.prevent="cancelOn(); dialogHelp = true;">Confused? Read our help...</a>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="on()" :loading="onLoading" min-width="100px">OK</v-btn>
                <v-btn color="default" text @click="cancelOn()">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOff" persistent max-width="500px">
        <v-card>
            <v-card-text>
                <div class="pt-8">
                    <p class="text-center mb-3"><v-icon large color="deep-orange accent-4">mdi-bell-off-outline</v-icon></p>
                    <p class="text-center mb-0">{{offq}}</p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="off()">Turn Off</v-btn>
                <v-btn color="default" text @click="cancelOff()">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHelp" scrollable max-width="800px">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="grey">mdi-help-circle-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <slot 
                        name="help" 
                        v-bind:monitor="monitor"
                    ></slot>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="dialogHelp = false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>
import {mapState} from 'vuex'

import System from '@/classes/System'
import Monitoring from '@/classes/Monitoring'
import Interface from '@/classes/Interface'
import Scheduler from '@/classes/Scheduler'
import Dates from '@/classes/Dates'

import {SITE_STATUS} from '@/constants/common'

export default {
    name: 'Monitor',
    props: ['iid', 'label', 'code', 'options', 'form', 'offq'],
    data () {
        return {
            // Constants
            SITE_STATUS,
            // Monitor
            monitor: false,
            history: [],
            error: false,
            timer: false,
            // On off switch
            onoffSwitch: undefined,
            onoffLoading: false,
            onoffDisabled: false,
            // Data loading
            onLoading: false,
            fetchLoading: false,
            // Dialogs
            dialogSettings: false,
            dialogOff: false,
            dialogHelp: false,
            // Chart
            showChart: false,
            chartDate: '',
            menuCal: false,
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        icon () {
            return Monitoring.iconByMonitor(this.code);
        },
        status () {
            return this.monitor && this.monitor.status || SITE_STATUS.UNKNOWN;
        },
        hasChart () {
            return !!this.$slots.chart;
        },
        today () {
            return Dates.today();
        },
        histLength () {
            return Dates.daysAgo(30);
        },
        maxWhen () {
            let result = 0;

            this.history.forEach(item => {
                result = Math.max(new Date(result).getTime(), new Date(item.when).getTime());
            });

            return result;
        },
    },
    methods: {
        // Init
        init () {
            this.$emit('init');
            this.monitor = false;
            this.error = false;
            this.chartDate = this.today;
            this.history = [];
            this.emitHistNew([]);
            this.fetchStatus().then(() => {
                this.emitOptions();
            });
            // this.fetchStatus().then(data => {
            //     this.emitOptions();
            //     if (data.enabled && data.status != SITE_STATUS.UNKNOWN && this.hasChart) {
            //         return this.fetchLast();
            //     }
            // });
        },
        resetMain () {
            this.onoffSwitch = this.monitor.enabled;
        },
        // Events
        emitOptions () {
            this.$emit('options', this.monitor.options);
        },
        emitEvents (enabled, status) {
            this.$emit('enabled', enabled);

            if (enabled) {
                this.$emit('status', status);
            } else {
                this.$emit('status', SITE_STATUS.UNKNOWN);
            }
        },
        emitHistAdd (items) {
            this.$emit('histadd', items);
        },
        emitHistNew (items) {
            this.$emit('histnew', items);
        },
        // API data calls
        fetchStatus () {
            this.fetchLoading = true;

            return Monitoring.get(this.code, this.iid)
                .then(data => {
                    this.error = false;
                    this.monitor = data;
                    // this.emitOptions();
                    this.emitEvents(this.monitor.enabled, this.monitor.status);
                    return data;
                })
                .catch(error => {
                    this.monitor = false;
                    this.error = true;
                    this.emitEvents(false, SITE_STATUS.UNKNOWN);
                    System.apiDecline(error);
                })
                .finally(() => {
                    this.fetchLoading = false;
                    this.resetMain();
                    this.scheduleNext();
                });
        },
        fetchLast () {
            return Monitoring.hist(this.code, this.iid, false, false, 25)
                .then(data => {
                    this.history = data;
                    this.emitHistNew(data);
                })
                .catch(error => {
                    this.history = [];
                    this.emitHistNew([]);
                    System.apiDecline(error, Interface.snackError);
                });
        },
        fetchDate () {
            return Monitoring.hist(this.code, this.iid, Dates.daystart(this.chartDate), Dates.dayend(this.chartDate))
                .then(data => {
                    this.history = data;
                    this.emitHistNew(data);
                })
                .catch(error => {
                    this.history = [];
                    this.emitHistNew([]);
                    System.apiDecline(error, Interface.snackError);
                });
        },
        // fetchNew () {
        //     return Monitoring.hist(this.code, this.iid, new Date(this.maxWhen + 1))
        //         .then(data => {
        //             if (data.length) {
        //                 this.history.push(...data);
        //                 this.emitHistAdd(data);
        //             }
        //         });
        // },
        // Timer functions
        scheduleNext () {
            let max = 3600000;
            let min = 0;
            let add = 60000;

            if (!this.monitor || !this.monitor.enabled || this.monitor.status == SITE_STATUS.UNKNOWN) {
                min = 60000;
                max = 60000;
                add = 0;
            }

            this.scheduleStop();
            this.timer = Scheduler.at(this.monitor.nextCheck || min, this.refresh, {min, max, add});
        },
        scheduleStop () {
            this.timer = Scheduler.stop(this.timer);
        },
        // On off switch
        onoff () {
            if (this.onoffSwitch) {
                this.onoffLoading = true;
                this.onoffDisabled = true;
                this.settings();
            } else {
                this.dialogOff = true;
            }
        },
        cancelOff () {
            this.dialogOff = false;
            this.resetMain();
        },
        cancelOn () {
            this.dialogSettings = false;
            this.onoffLoading = false;
            this.onoffDisabled = false;
            this.resetMain();
        },
        off () {
            this.onoffLoading = true;
            this.onoffDisabled = true;
            this.dialogOff = false;

            Monitoring.off(this.code, this.iid)
                .then(data => {
                    this.monitor = data;
                    this.history = [];
                    this.emitEvents(false, SITE_STATUS.UNKNOWN);
                    this.emitHistNew([]);
                })
                .catch(error => {
                    System.apiDecline(error, Interface.snackError);
                })
                .finally(() => {
                    this.onoffLoading = false;
                    this.onoffDisabled = false;
                    this.resetMain();
                    this.scheduleNext();
                });
        },
        on () {
            if (!this.form.validate()) {
                Interface.snackError('Some of the options are invalid');
                return false;
            }

            this.onLoading = true;

            Monitoring.on(this.code, this.iid, this.options)
                .then(data => {
                    this.monitor = data;
                    this.history = [];
                    this.emitEvents(this.monitor.enabled, this.monitor.status);
                    // this.emitHistNew([]);
                    // this.fetchLast();
                })
                .catch(error => {
                    System.apiDecline(error, Interface.snackError);
                })
                .finally(() => {
                    this.onoffLoading = false;
                    this.onoffDisabled = false;
                    this.dialogSettings = false;
                    this.onLoading = false;
                    this.resetMain();
                    this.emitOptions();
                    this.scheduleNext();
                });
        },
        // Other functions
        settings () {
            this.emitOptions();
            this.dialogSettings = true;
        },
        refresh () {
            this.error = false;
            this.fetchStatus();
            // this.fetchStatus().then(data => {
            //     if (data.enabled && data.status != SITE_STATUS.UNKNOWN) {
            //         if (this.maxWhen > 0)
            //             return this.fetchNew();
            //         else
            //             return this.fetchLast();
            //     }
            // });
        },
        humandate (date) {
            return Dates.human(date);
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        formValid () {
            return this.form && this.form.validate() || false;
        },
    },
    mounted () {
        this.init();
    },
    beforeDestroy () {
        this.scheduleStop();
    },
    watch: {
        'iid': 'init',
        status (newv, oldv) {
            if (newv != SITE_STATUS.UNKNOWN && oldv == SITE_STATUS.UNKNOWN && this.hasChart) this.fetchLast();
        }

    },    
}
</script>


<style scoped>
</style>
