<template>
    <v-bottom-sheet v-model="show">
        <v-sheet
            class="text-center"
            height="200px"
        >
            <v-btn
                class="mt-6"
                text
                color="primary"
                width="200"
                @click="show = !show"
            >
                close
            </v-btn>
            <div class="pb-3 pt-6">
                <p>You have reached your subscription plan limit. Please upgrade to the next subscription level.</p>
                <v-icon large color="primary">mdi-cart-arrow-up</v-icon>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</template>


<script>
export default {
    name: 'PlanError',
    data () {
        return {
        }
    },
    computed: {
        show: {
            get () {
                return this.$store.state.system.planerror;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/setPlanError', false);
                }
            }
        },
    },
}
</script>


<style scoped>
</style>
