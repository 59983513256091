<template>
    <v-progress-linear indeterminate fixed top color="primary" :active="showLoading" style="z-index: 9999"></v-progress-linear>
</template>


<script>
export default {
    name: 'Loading',
    computed: {
        showLoading () {
            return this.$store.state.system.loading
        }
    },
}
</script>


<style scoped>
</style>
