<template>
<div>
    <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
        <v-sheet v-if="isMedium" elevation="1" rounded="lg" width="600" height="600" class="mr-1">
            <div class="med-sheet rounded-lg">
                <div class="text-center pt-9 text-h5 font-weight-thin amber--text text--lighten-1">
                    <p class="mb-2">Tools for website Owners</p>
                    <p class="mb-2">Admins and Developers</p>
                </div>
                <div class="text-center pt-12 text-h5 font-weight-thin white--text">
                    <p class="">Uptime Monitoring</p>
                    <p class="">Performance Monitoring</p>
                    <p class="">Performance Reports</p>
                    <p class="">SEO Reports</p>
                    <p class="">Email Alerts</p>
                    <div class="mt-16">
                        <v-btn 
                            @click="$router.push({ name: 'register' })" 
                            rounded
                            large
                            outlined
                            color="white"
                        >
                            Create New Account
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>    
        <v-sheet v-if="isBig" elevation="1" rounded="lg" width="900" height="600" class="mr-1">
            <div class="big-sheet rounded-lg pt-5">
                <v-row>
                    <v-col class="text-center pt-9 text-h4 font-weight-thin amber--text text--lighten-1">
                        <p class="mb-2">Tools for website</p>
                        <p class="mb-2">Owners, Admins</p>
                        <p class="mb-2">and Developers</p>
                    </v-col>
                    <v-col class="text-left pt-9 ml-10 text-h5 font-weight-thin white--text">
                        <p class="">Uptime Monitoring</p>
                        <p class="">Performance Monitoring</p>
                        <p class="">Performance Reports</p>
                        <p class="">SEO Reports</p>
                        <p class="">Email Alerts</p>
                        <div class="text-left mt-16">
                            <v-btn 
                                @click="$router.push({ name: 'register' })" 
                                rounded
                                large
                                outlined
                                color="white"
                            >
                                Create New Account
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-sheet>    
        <v-sheet elevation="1" rounded="lg" width="350" height="600" class="pa-8">
            <v-form ref="loginForm">
                <div class="mt-10">
                    <v-text-field 
                        label="Email"
                        hint="Enter your login email"
                        ref="email"
                        type="email"
                        v-model="email"
                        autocomplete="username"
                        append-icon="mdi-account"
                        :rules="[
                            () => !!email || 'Enter your login email',
                            () => /.+@.+/.test(email) || 'Must be a valid email',
                        ]"
                        required
                    ></v-text-field>
                    <v-text-field 
                        label="Password" 
                        hint="Enter your password"
                        :type="show ? 'text' : 'password'"
                        v-model="password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        autocomplete="current-password"
                        :rules="[() => !!password || 'Enter your password']"
                        required
                        @click:append="show = !show"
                    ></v-text-field>
                </div>
                <div class="mt-10">
                    <v-btn 
                        class="mt-4" 
                        type="submit"
                        color="primary" 
                        block
                        :loading="loading"
                        @click.prevent="login()"
                    >Login</v-btn>
                </div>
                <div class="mt-8">
                    <div id="gauth"></div>
                </div>
                <div class="mt-2">
                    <v-btn @click="loginFB()" block class="text-none text-body-2 white--text" elevation="0" color="#4267B2"><v-icon left color="white">mdi-facebook</v-icon>Sign in with Facebook</v-btn>
                </div>
                <div class="mt-10">
                    <v-checkbox 
                        v-model="save"
                        label="Remember me"
                    ></v-checkbox>
                </div>
                <div class="mt-3">
                    <p class="mb-2 pa-0"><router-link :to="{ name: 'remindpwd' }" class="text-body-2">Forgot password?</router-link></p>
                    <p class="ma-0 pa-0"><router-link :to="{ name: 'register' }" class="text-body-2">Register new account</router-link></p>
                </div>
            </v-form>
        </v-sheet>
    </div>
    <div class="text-center mt-10">
        <p class="mt-5 text-caption">By using this service you agree with <router-link :to="{name: 'terms', query: {back: 'login'}}">Terms and Privacy</router-link> policy. This website is using local storage to save the unsensitive session data.</p>
        <p class="mt-5 text-caption">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
    </div>
</div>
</template>


<script>
import { mapState } from 'vuex';

import Interface from "@/classes/Interface";
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'Login',
    data () {
        return {
            email: '',
            password: '',
            save: false,
            token: '',
            show: false,
            loading: false,
        }
    },
    computed: {
        ...mapState({
            system: state => state.system,
        }),
        loginForm () {
            return {
                email: this.email,
                password: this.password,
                save: this.save,
                token: this.token,
            }
        },
        isBig () {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        isMedium () {
            return this.$vuetify.breakpoint.mdOnly;
        },
    },
    methods: {
        async login () {
            if (this.$refs.loginForm.validate()) {
                Interface.alertOff();
                this.loading = true;

                try {
                    System.debug('Captcha key: ', this.$recaptchaInstance.siteKey);
                    System.debug('Loading captcha');
                    await this.$recaptchaLoaded();
                    System.debug('Captcha loaded');
                    this.token = await this.$recaptcha('login');
                    System.debug('Captcha ok');
                } catch (error) {
                    System.debug('Captcha failed');
                    this.loading = false;
                    this.token = false;
                    Interface.alertError('reCAPTCHA validation failed');
                    return false;
                }

                return User.login(this.loginForm)
                    .then(() => {
                        System.redirectToSaved('/');
                    })
                    .catch(error => {
                        this.password = '';
                        this.$refs.loginForm.resetValidation()
                        Interface.alertError(error.message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        loginG (response) {
            System.debug('Google auth: ', response);

            User.loginG({
                token: response.credential || '', 
                save: this.save
            })
            .then(() => {
                System.redirectToSaved('/');
            })
            .catch(error => {
                Interface.alertError(error.message);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        loginFB () {
            window.FB.login(response => {
                System.debug('Facebook auth: ', response);

                if (response.status == "connected") {
                    Interface.alertOff();
                    this.loading = true;

                    User.loginFB({
                        token: response.authResponse && response.authResponse.accessToken || '', 
                        save: this.save
                    })
                    .then(() => {
                        System.redirectToSaved('/');
                    })
                    .catch(error => {
                        Interface.alertError(error.message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                }
            }, {scope: 'public_profile, email'});
        },
    },
    mounted () {
        // let recaptcha = this.$recaptchaInstance;
        // recaptcha.siteKey = this.system.settings.captchaSiteKey;
        // System.debug('Captcha key: ', recaptcha.siteKey);
        // System.debug('Captcha: ', recaptcha);
        
        let googleClientId = this.system.settings.googleClientId;
        System.debug('Google client id: ', googleClientId);

        System.loadScript('https://accounts.google.com/gsi/client')
            .then(() => {
                window.google.accounts.id.initialize({
                    // client_id: '218968553624-55c3okkqdvh3f2v4gpqafhu0ejolaevh.apps.googleusercontent.com',
                    client_id: googleClientId,
                    callback: this.loginG,
                });
                window.google.accounts.id.renderButton(
                    document.getElementById("gauth"),
                    {theme: "outline", size: "large", width: "285"}
                );
                
            });

        let fbAppId = this.system.settings.fbAppId;
        System.debug('FB app id: ', fbAppId);

        System.loadScript('https://connect.facebook.net/en_US/sdk.js')
            .then(() => {
                window.fbAsyncInit = function() {
                    window.FB.init({
                        // appId            : '936448327224225',
                        appId            : fbAppId,
                        autoLogAppEvents : true,
                        xfbml            : true,
                        version          : 'v12.0'
                    });
                };
             
            });
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}

.med-sheet {
    height: 100%;
    /* background-color: blueviolet; */
    background-image: url('/images/bg02.jpg');
    background-size: cover;
}
.big-sheet {
    height: 100%;
    /* background-color: blueviolet; */
    background-image: url('/images/bg01.jpg');
    background-size: cover;
}
</style>
