<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">My Websites</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Enter full URL of your website or a page to add it to the list</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-1">Manage your websites and pages</p>
        </div>

        <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
                <v-btn icon absolute top right v-bind="attrs" v-on="on" @click.stop="getSites()" :loading="loadingRef"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>    
            <span>Refresh My Websites</span>
        </v-tooltip>

        <div>
            <v-expansion-panels hover value="0" v-model="addPanel">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span v-if="countSites">Add new URL:</span>
                        <span v-else class="primary--text">Add your first website to begin:</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row justify="center">
                            <v-col cols="12" sm="8">
                                <div class="my-6 text-center">
                                    <v-form ref="addSiteForm">
                                        <v-text-field 
                                            label="Enter URL"
                                            hint="Enter your website or page full URL"
                                            placeholder="https://www.yourdomain.com/page..."
                                            ref="url"
                                            type="url"
                                            v-model="url"
                                            append-icon="mdi-map-marker-plus"
                                            :rules="[
                                                () => !!url || 'Enter your website or page full URL',
                                                () => this.isURL(url) || 'Must be a valid URL',
                                            ]"
                                            required
                                        ></v-text-field>
                                        <v-btn
                                            class="my-2"
                                            color="primary"
                                            type="submit"
                                            tile
                                            :loading="loadingAdd"
                                            @click.prevent="addSite()"
                                        >Add New URL</v-btn>                            
                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div class="mt-4 truncate" v-if="countSites">
            <v-data-table
                :headers="headers"
                :items="sites"
                :search="search"
                :items-per-page="25"
                :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                show-expand
                :expanded.sync="expanded"
                mobile-breakpoint="0"
            >
                <template v-slot:[`body.prepend`]>
                    <tr>
                        <td></td>
                        <td>
                            <v-text-field
                                v-model="search"
                                label="Search"
                                single-line
                                hide-details
                                dense
                                outlined
                                clearable
                            ></v-text-field>                    
                        </td>
                        <td></td>
                        <td>
                            <v-select
                                v-model="selectedStatus"
                                :items="statusItems"
                                single-line
                                hide-details
                                dense
                                outlined
                            ></v-select>
                        </td>
                        <td></td>
                    </tr>
                </template>

                <template v-slot:expanded-item="{item, isMobile}">
                    <td :colspan="headers.length" class="pa-0">
                        <div v-if="isMobile">
                            Mobile version
                        </div>
                        <div v-else>
                            <v-simple-table class="grey lighten-5 rounded-0">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="page in item.pages" :key="page.id" >
                                            <td style="width: 55px"></td>
                                            <td colspan="2">
                                                <router-link :to="{name: 'monitor-page', params: {id: page.id}}" class="teal--text text--darken-2">
                                                    <code>{{pageUrl(page.url)}}</code>
                                                </router-link>
                                            </td>
                                            <td style="width: 30%" class="text-center" nowrap>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.statusUptime)" v-bind="attrs" v-on="on" class="mr-4">mdi-file-document-outline</v-icon>
                                                    </template>
                                                    <span>Uptime Status: {{descr(page.statusUptime)}}</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.statusHtml)" v-bind="attrs" v-on="on" class="mr-4">mdi-file-code-outline</v-icon>
                                                    </template>
                                                    <span>HTML Status: {{descr(page.statusHtml)}}</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.perfMobile)" v-bind="attrs" v-on="on" class="mr-4">mdi-cellphone</v-icon>
                                                    </template>
                                                    <span>Mobile Performance: {{descr(page.perfMobile)}}</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-icon @click="navToPageMon(page.id)" :color="color(page.perfDesktop)" v-bind="attrs" v-on="on">mdi-monitor</v-icon>
                                                    </template>
                                                    <span>Desktop Performance: {{descr(page.perfDesktop)}}</span>
                                                </v-tooltip>
                                            </td>
                                            <td style="width: 10%" class="text-center">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-icon @click="deletedPage = page; dialogDeletePage = true;" v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                                                    </template>
                                                    <span>Remove Page</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </td>
                </template>

                <template v-slot:[`item.url`]="{item}">
                    <router-link :to="{name: 'monitor-site', params: {id: item.id}}">
                        {{item.url}}
                    </router-link>
                </template>

                <template v-slot:[`item.statusGroup`]="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon @click="navToSiteMon(item.id)" :color="color(item.statusDomain)" v-bind="attrs" v-on="on" class="mr-4">mdi-domain</v-icon>
                        </template>
                        <span>Domain Name Status: {{descr(item.statusDomain)}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon @click="navToSiteMon(item.id)" :color="color(item.statusSsl)" v-bind="attrs" v-on="on" class="mr-4">mdi-certificate</v-icon>
                        </template>
                        <span>Certificate Status: {{descr(item.statusSsl)}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon @click="navToSiteMon(item.id)" :color="color(item.statusPing)" v-bind="attrs" v-on="on" class="mr-4">mdi-gauge</v-icon>
                        </template>
                        <span>Ping Status: {{descr(item.statusPing)}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon @click="expanded.push(item)" :color="color(item.statusPages)" v-bind="attrs" v-on="on">mdi-file-multiple</v-icon>
                        </template>
                        <span>Pages Status: {{descr(item.statusPages)}}</span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon @click="deletedSite = item; dialogDeleteSite = true;" v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                        </template>
                        <span>Remove Site</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </div>
        <div v-else class="pt-16 mx-2 text-center">
            <v-row style="min-height: 110px">
                <v-col cols="2" class="hidden-sm-and-down">
                    <v-icon size="45" color="blue lighten-3">mdi-numeric-1-circle-outline</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-2 text-left blue--text text--lighten-2 mb-0">Add your first website</p>
                    <!-- <p class="text-caption text-left grey--text">It will appear in the websites list on this page and in the right menu. You can show or hide the right menu by pressing <v-icon>mdi-server</v-icon> button.</p> -->
                    <p class="text-caption text-left grey--text">It will appear in the websites list on this page and in the right menu. There are two types of monitors in our system: Server level and Page level. Server level monitors measure characteristics related to the whole website and are common to all pages of this website, such as domain name or SSL certificate. Meanwhile page level monitors check characteristics of each individual page, such as page performance or HTML validation errors.</p>
                </v-col>
            </v-row>
            <v-row style="min-height: 110px">
                <v-col cols="2" class="hidden-sm-and-down">
                    <v-icon size="45" color="indigo lighten-4">mdi-numeric-2-circle-outline</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-2 text-left indigo--text text--lighten-3 mb-0">Server level monitoring </p>
                    <p class="text-caption text-left grey--text">
                        You can enable following server and domain level monitors for all your websites:
                        <ul>
                            <li>Domain Name Monitor - Checks related to your website’s domain name.</li>
                            <li>Certificate Monitor - Checks related to your website’s SSL certificate.</li>
                            <li>Ping Monitor - Ping your web server from various world locations to measure the network speed.</li>
                        </ul>
                    </p>
                </v-col>
            </v-row>
            <v-row style="min-height: 110px">
                <v-col cols="2" class="hidden-sm-and-down">
                    <v-icon size="45" color="deep-purple lighten-4">mdi-numeric-3-circle-outline</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-2 text-left deep-purple--text text--lighten-3 mb-0">Page level monitoring </p>
                    <p class="text-caption text-left grey--text">
                        You can enable following web page level monitors for all your pages:
                        <ul>
                            <li>Uptime Monitor - Various checks related to page availability, content and load time.</li>
                            <li>HTML Validator - Constant page HTML validation to ensure high search engine rank.</li>
                            <li>Mobile Performance - Monitors how well your website works on mobile devices.</li>
                            <li>Desktop Performance - Monitors how well your website works on desktop computers and laptops.</li>
                        </ul>
                    </p>
                </v-col>
            </v-row>
        </div>    


    </v-sheet>

    <v-dialog v-model="dialogDeleteSite" max-width="500px">
        <v-card>
            <v-card-title class="subtitle-1">Remove this site and all its pages?</v-card-title>
            <v-card-text class="pt-4 pb-0">URL: {{deletedSite.url}}</v-card-text>
            <v-card-text>Pages: {{deletedSite.countPages}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteSite(); dialogDeleteSite = false">Remove</v-btn>
                <v-btn color="default" text @click="dialogDeleteSite = false">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeletePage" max-width="500px">
        <v-card>
            <v-card-title class="subtitle-1">Remove this page?</v-card-title>
            <v-card-text class="pt-4">URL: {{pageUrl(deletedPage.url)}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deletePage(); dialogDeletePage = false">Remove</v-btn>
                <v-btn color="default" text @click="dialogDeletePage = false">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAdded" max-width="800px" v-if="added">
        <v-card>
            <v-card-title class="subtitle-1 success--text">Success!</v-card-title>
            <v-card-text class="success--text font-weight-medium" v-if="added.type == 1">New website and page added</v-card-text>
            <v-card-text class="success--text font-weight-medium" v-if="added.type == 2">New page added to existing website</v-card-text>
            <v-card-text class="pb-1"><strong class="mr-2">Site:</strong> {{added.site.url}}</v-card-text>
            <v-card-text class="pb-1"><strong class="mr-2">Page:</strong> {{pageUrl(added.page.url)}}</v-card-text>
            <v-card-text><strong class="mr-2">Email:</strong> {{email}}</v-card-text>
            <v-card-text><a href="#" @click.prevent="showAddedHelp = !showAddedHelp">What shall I do next?</a></v-card-text>
            <v-expand-transition>
                <div v-if="showAddedHelp" class="mx-2 grey--text text--darken-2">
                    <v-card-text>Your new site will now appear in the list. Exapnd the row to see its pages.</v-card-text>
                    <v-img src="/images/man002.jpg" class="rounded-xl mx-4"></v-img>
                    <v-card-text>We have enabled all monitors with default settings for you. Give it few minutes to collect initial data. Check the icons in the table to see the status of each monitor. Colors will change from green to yellow or red when there is a problem. Click on the icon to go to the details screen.</v-card-text>
                    <v-img src="/images/man003.jpg" class="rounded-xl mx-4"></v-img>
                    <v-card-text>You can switch ON and OFF each monitor, adjust monitor settings such as check frequency and more using monitor widget buttons.</v-card-text>
                    <v-img src="/images/man005.jpg" class="rounded-xl mx-4"></v-img>
                    <v-card-text>Whenever there is a problem we will send notification to your email: <strong>{{email}}</strong></v-card-text>
                </div>
            </v-expand-transition>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialogAdded = false; added = false">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>
import {mapState} from 'vuex'

import Interface from "@/classes/Interface";
import System from '@/classes/System'
import Sites from '@/classes/Sites'
import Monitoring from '@/classes/Monitoring'
import Utils from '@/classes/Utils'

export default {
    name: 'Websites',
    data () {
        return {
            url: '',
            search: '',
            addPanel: undefined,
            loadingAdd: false,
            loadingRef: false,
            dialogDeleteSite: false,
            dialogDeletePage: false,
            dialogAdded: false,
            deletedSite: false,
            deletedPage: false,
            added: false,
            showAddedHelp: false,
            headers: [
                {
                    text: '',
                    width: '55px',
                    value: 'data-table-expand',
                },
                {
                    text: 'URL',
                    sortable: true,
                    filterable: true,
                    value: 'url',
                },
                {
                    text: 'Pages',
                    sortable: true,
                    filterable: false,
                    width: '10%',
                    align: 'center',
                    value: 'countPages',
                },
                {
                    text: 'Status',
                    sortable: true,
                    filterable: true,
                    width: '30%',
                    align: 'center',
                    value: 'statusGroup',
                    filter: this.filterStatus,
                },
                {
                    text: 'Actions',
                    sortable: false,
                    filterable: false,
                    width: '10%',
                    align: 'center',
                    value: 'actions',
                },
            ],
            statusItems: [
                {value: 0, text: 'All statuses'},
                {value: 2, text: 'Warnings and Errors'},
                {value: 3, text: 'Errors'},
            ],
            selectedStatus: 0,
            expanded: [],
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
            email: state => state.user.data.email,
        }),
        countSites () {
            return this.sites.length;
        },
        addSiteForm () {
            return {
                url: this.url,
            }
        },
    },
    methods: {
        async getSites() {
                this.loadingRef = true;

                return Sites.get()
                    .catch(error => {
                        System.apiDecline(error, Interface.alertError);
                    })
                    .finally(() => {
                        this.loadingRef = false;
                    });
        },
        async addSite () {
            if (this.$refs.addSiteForm.validate()) {
                Interface.alertOff();
                this.loadingAdd = true;

                return Sites.addURL(this.addSiteForm)
                    .then(data => {
                        this.url = '';
                        this.added = data;
                        this.$refs.url.blur();
                        this.$refs.addSiteForm.reset();
                        this.closeAddPanel();
                        this.showAddedHelp = this.countSites == 1 && this.added.site.pages.length <= 2;
                        this.dialogAdded = true;
                        return data;
                    })
                    .catch(error => {
                        System.apiDecline(error, Interface.alertError);
                    })
                    .finally(() => {
                        this.loadingAdd = false;
                    });
            }
        },
        async deleteSite() {
            Interface.alertOff();

            return Sites.deleteSite(this.deletedSite.id)
                .then(data => {
                    Interface.snackSuccess('Website removed!');
                    return data;
                })
                .catch(error => {
                    System.apiDecline(error, Interface.alertError);
                })
        },
        async deletePage() {
            Interface.alertOff();

            return Sites.deletePage(this.deletedPage.id)
                .then(data => {
                    Interface.snackSuccess('Page removed');
                    return data;
                })
                .catch(error => {
                    System.apiDecline(error, Interface.alertError);
                })
        },
        openAddPanel () {
            this.addPanel = 0;
        },
        closeAddPanel () {
            this.addPanel = undefined;
        },
        isURL(str) {
            return Utils.isURL(str);
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        descr (status) {
            return Monitoring.descrByStatus(status);
        },
        navToSiteMon (id) {
            System.redirectTo({name: 'monitor-site', params: {id}});
        },
        navToPageMon (id) {
            System.redirectTo({name: 'monitor-page', params: {id}});
        },
        filterStatus (value) {
            return value >= this.selectedStatus;
        },
        pageUrl (url) {
            return Sites.pageUrl(url);
        },
    },
    created () {
        Interface.alertOff();
        
        if (this.countSites === 0 || this.$route.query.add === true) {
            this.openAddPanel();
        }
        window.$app.$on('open-addurl', () => {
            this.openAddPanel();
        });
    },
}
</script>


<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #FAFAFA;
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
