<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mb-3" style="position: relative">
        <p class="text-h5 font-weight-light text-center mb-2">Server Monitoring</p>
        <div v-if="site">
            <p class="text-body-2 text--secondary text-center mb-2">
                <a :href="site.url" target="_blank">{{site.url}}</a>
            </p>
            <p class="text-caption font-weight-light text--secondary text-center mb-2">
                {{enabled}} of {{total}} monitors enabled
            </p>
            <p class="text-center mb-0" v-if="ok || warnings || errors">
                <span v-if="ok" class="success--text mx-2"><v-icon color="success">mdi-checkbox-marked-circle</v-icon> {{ok}}</span>
                <span v-if="warnings" class="warning--text mx-2"><v-icon color="warning">mdi-alert-circle</v-icon> {{warnings}}</span>
                <span v-if="errors" class="error--text mx-2"><v-icon color="error">mdi-alert</v-icon> {{errors}}</span>
            </p>
        </div>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon absolute top left v-bind="attrs" v-on="on" @click.stop="$router.push({name: 'websites'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>    
            <span>To My Websites</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
                <v-btn icon absolute top right style="margin-right: -4px" v-bind="attrs" v-on="on" @click.stop="dialogHelp = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
            </template>    
            <span>Description</span>
        </v-tooltip>

    </v-sheet>

    <div v-if="site">
        <MonitorDomain 
            :site="site"
            @status="updateStatus(monitors.domain, 'statusDomain', $event)"
            @enabled="monitors.domain.enabled = $event" 
        />
        <MonitorSsl 
            v-if="isHttps(site.url)"
            :site="site"
            @status="updateStatus(monitors.ssl, 'statusSsl', $event)"
            @enabled="monitors.ssl.enabled = $event" 
        />
        <MonitorEmpty
            v-else 
            label="Certificate Monitor"
            icon="mdi-close-circle-outline"
            message="Disabled for http sites..."
        />
        <MonitorEmpty 
            label="Ping Monitor"
            icon="mdi-dna"
            message="Under construction, coming soon..."
        />
        <MonitorPages 
            :site="site" 
        />
    </div>

    <v-dialog v-model="dialogHelp" scrollable max-width="800px">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="grey">mdi-help-circle-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <p class="text-body-1 text-center">Server Level Monitoring</p>
                    <p>
                        There are two types of monitors in our system: Server level and Page level. Server level monitors measure characteristics related to the whole website and are common to all pages of this website, such as domain name or SSL certificate. 
                        Meanwhile page level monitors check characteristics of each individual page, such as page performance or HTML validation errors.
                    </p>
                    <p>
                        This section represents server level monitors. We offer following checks to make sure your web server is up and accessible:
                        <ul>
                            <li>Domain Name Monitor - Checks related to your website’s domain name.</li>
                            <li>Certificate Monitor - Checks related to your website’s SSL certificate.</li>
                            <li>Ping Monitor - Ping your web server from various world locations to measure the network speed.</li>
                        </ul>
                    </p>
                    <p>
                        Press Description button on each widget to read more about each of the monitors and their options.
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="dialogHelp = false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>
import {mapState} from 'vuex'

import {SITE_STATUS} from '@/constants/common'
import Monitoring from '@/classes/Monitoring'
import Sites from '@/classes/Sites'
// import Interface from '@/classes/Interface'

import MonitorDomain from '@/components/monitoring/MonitorDomain'
import MonitorSsl from '@/components/monitoring/MonitorSsl'
import MonitorPages from '@/components/monitoring/MonitorPages'
import MonitorEmpty from '@/components/monitoring/MonitorEmpty'

export default {
    name: 'MonitorSite',
    components: {
        MonitorDomain,
        MonitorSsl,
        MonitorPages,
        MonitorEmpty,
    },
    data () {
        return {
            site: false,
            total: 3,
            monitors: {
                ping: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
                ssl: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
                domain: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
            },
            dialogHelp: false,
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        siteID () {
            return Number(this.$route.params.id) || false;
        },
        enabled () {
            return Monitoring.statsSite(this.monitors).enabled || 0;
        },
        ok () {
            return Monitoring.statsSite(this.monitors).ok || 0;
        },
        warnings () {
            return Monitoring.statsSite(this.monitors).warning || 0;
        },
        errors () {
            return Monitoring.statsSite(this.monitors).error || 0;
        },
    },
    methods: {
        init () {
            this.$vuetify.goTo(0);
            // this.total = 0;
            this.site = Sites.getSiteEntry(this.sites, this.siteID);
        },
        updateStatus (monitor, field, status) {
            monitor.status = status;
            this.site[field] = status;
            Sites.computeSiteData(this.site);
        },
        isHttps (url) {
            return Sites.isHttps(url);
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        'siteID': 'init',
    },    
}
</script>


<style scoped>
</style>
