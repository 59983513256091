<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">My Account</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Change your data and password</p>
        </div>
        <div class="pt-8 pb-4">
            <v-row justify="center">
                <v-col cols="12" sm="8">
                    <v-form ref="userForm" v-model="userFormValid" :disabled="accExt">
                        <v-text-field
                            v-model="name"
                            type="text"
                            append-icon="mdi-account"
                            placeholder="Your name..."
                            label="Your name"
                            counter="100"
                            required
                            :rules="[
                                (v) => !!v || 'Enter your name',
                                (v) => v.length <= 100 || 'Name too long',
                            ]"
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            type="email"
                            append-icon="mdi-email"
                            placeholder="Your email..."
                            label="Your email"
                            counter="100"
                            required
                            disabled
                            :rules="[
                                (v) => !!v || 'Enter your email',
                                (v) => v.length <= 100 || 'Email too long',
                                () => /.+@.+/.test(email) || 'Must be a valid email',
                            ]"
                            class="mb-4"
                        ></v-text-field>
                        <v-btn 
                            class="mr-4 mb-2" 
                            color="primary" 
                            width="150" 
                            v-if="accLocal" 
                            :block="isXS"
                            :loading="saveLoading"
                            @click="saveData()"
                        >
                            Save
                        </v-btn>
                        <v-btn 
                            class="mr-4 mb-2" 
                            color="secondary" 
                            v-if="accLocal"
                            :block="isXS"
                            @click="showPassDialog()"
                        >
                            Change password
                        </v-btn>
                        <p v-if="accFb"><v-icon color="#4267B2" large>mdi-facebook</v-icon></p>
                        <p v-if="accGoogle"><v-icon color="#DB4437" large>mdi-google</v-icon></p>
                    </v-form>
                </v-col>
            </v-row>
        </div>

    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="mt-3 pa-5" style="position: relative">

        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">My Subscription</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-0">Manage subscription plan</p>
        </div>
        <div class="pt-8 pb-4">
            <v-row>
                <v-col cols="12" :sm="planSize" v-for="plan in plans" :key="plan.code">
                    <v-card width="100%" height="350" elevation="0" outlined class="pa-2 text-center">
                        <p class="mt-8 secondary--text font-weight-medium text-uppercase">{{plan.name}}</p>
                        <template v-if="plan.code == user.data.plan">
                            <p class="mt-8 mb-0 info--text">FREE</p>
                            <p class="mt-0 info--text text-caption">for beta testing period</p>
                        </template>
                        <p class="mt-8 info--text" v-else>{{planPrice(plan.price)}}</p>
                        <p class="mt-8 mb-1 text-caption">{{planWebsites(plan.limits.sites)}}</p>
                        <p class="mt-1 mb-1 text-caption">{{planPages(plan.limits.pages)}}</p>
                        <p class="mt-1 mb-1 text-caption">{{planMinInt(plan.limits.minInt)}}</p>
                        <p class="mt-10 success--text text--darken-1" v-if="plan.code == user.data.plan">ACTIVE</p>
                        <p class="mt-10 error--text text--darken-1" v-else>NOT AVAILABLE</p>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="text-center mt-5">
            <p class="mt-5 text-caption">Contact us if you need higher limits or to be unlimited...</p>
        </div>

    </v-sheet>


    <v-dialog v-model="dialogChPwd" max-width="400px" v-if="accLocal">
        <v-card>
            <v-card-title class="subtitle-1">Change your password</v-card-title>
            <div class="py-5 px-7">
                <v-form ref="passForm">
                    <v-text-field 
                        label="Current Password" 
                        hint="Your current password"
                        v-model="cpass"
                        counter="100"
                        autocomplete="current-password"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                            (v) => !!v || 'Enter your current password',
                            (v) => v.length <= 100 || 'Password too long',
                        ]"
                        required
                        @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field 
                        label="New Password" 
                        hint="Choose your new password"
                        v-model="npass"
                        counter="100"
                        autocomplete="new-password"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                            (v) => !!v || 'Enter your new password',
                            (v) => v.length <= 100 || 'Password too long',
                        ]"
                        required
                        @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field 
                        :disabled="show"
                        ref="npass2"
                        label="Password Confirmation" 
                        hint="Confirm your new password"
                        type="password"
                        autocomplete="new-password"
                        v-model="npass2"
                        counter="100"
                        append-icon="mdi-eye-check"
                        :rules="[
                            () => (show || npass == npass2) || 'Passwords do not match'
                        ]"
                        required
                    ></v-text-field>
                </v-form>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="changePwd()" :loading="passLoading">Change</v-btn>
                <v-btn color="default" text @click="dialogChPwd = false">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>
import {mapState} from 'vuex'

import Interface from "@/classes/Interface";
import System from '@/classes/System'
import User from '@/classes/User'

import {LOGIN_TYPE} from '@/constants/common'

export default {
    name: 'Account',
    data () {
        return {
            dialogChPwd: false,
            userFormValid: true,
            saveLoading: false,
            passLoading: false,
            show: false,
            name: '',
            email: '',
            cpass: '',
            npass: '',
            npass2: '',
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            plans: state => state.system.plans,
        }),
        isXS () {
            return this.$vuetify.breakpoint.xsOnly;
        },
        accLocal () {
            return this.user.session.type == LOGIN_TYPE.LOCAL;
        },
        accFb () {
            return this.user.session.type == LOGIN_TYPE.FB;
        },
        accGoogle () {
            return this.user.session.type == LOGIN_TYPE.GOOGLE;
        },
        accExt () {
            return this.accFb || this.accGoogle;
        },
        planSize () {
            switch (this.plans.length) {
                case 1: return 12;
                case 2: return 6;
                case 3: return 4;
                case 4: return 3;
                default: return 3;
            }
        },
    },
    methods: {
        init () {
            Interface.alertOff();
            this.name = this.user.data.name;
            this.email = this.user.data.email;
        },
        saveData () {
            if (this.$refs.userForm.validate()) {
                this.saveLoading = true;

                User.data({name: this.name})
                    .then(() => {
                        this.init();
                        Interface.snackSuccess('Saved!');
                    })
                    .catch(error => {
                        System.apiDecline(error, Interface.alertError);
                    })
                    .finally(() => {
                        this.saveLoading = false;
                    });
            }
        },
        changePwd () {
            if (this.$refs.passForm.validate()) {
                this.passLoading = true;

                User.pass(this.cpass, this.npass)
                    .then(() => {
                        Interface.snackSuccess('Changed!');
                    })
                    .catch(error => {
                        System.apiDecline(error, Interface.alertError);
                    })
                    .finally(() => {
                        this.passLoading = false;
                        this.dialogChPwd = false;
                    });
            }
        },
        showPassDialog () {
            this.cpass = '';
            this.npass = '';
            this.npass2 = '';
            this.dialogChPwd = true;
            this.$nextTick(() => this.$refs.passForm.resetValidation());
        },
        planPrice (price) {
            switch (price) {
                case  0: return 'FREE';
                default: return `${price} $/mo.`;
            }
        },
        planWebsites (cnt) {
            switch (cnt) {
                case 1: return `${cnt} website`;
                default: return `${cnt} websites`;
            }
        },
        planPages (cnt) {
            switch (cnt) {
                case 1: return `${cnt} page per website`;
                default: return `${cnt} pages per website`;
            }
        },
        planMinInt (int) {
            let mins = int/60;
            switch (mins) {
                case 1: return `Check every minute`;
                default: return `Check every ${mins} minutes`;
            }
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        show (newv) {
            if (newv) {
                this.npass2 = '';
                this.$refs.npass2.resetValidation();
            }
        },
    },
}
</script>


<style scoped>
</style>
