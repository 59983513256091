<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="mb-3">
        
        <div class="d-flex align-center">
            <div class="ml-7">
                <v-switch v-model="onoffSwitch" :disabled="true" :label="label"></v-switch>
            </div>
            <!-- <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon static top right class="mr-3" v-bind="attrs" v-on="on" @click.stop="dialogHelp = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
                </template>    
                <span>Description</span>
            </v-tooltip> -->
        </div>
        <div class="mx-6 mt-3 pb-6">
            <!-- <v-icon color="grey" large class="mr-3">mdi-dna</v-icon> -->
            <v-icon color="grey" large class="mr-3">{{icon}}</v-icon>
            <!-- <span class="grey--text">Under construction, coming soon...</span> -->
            <span class="grey--text">{{message}}</span>
        </div>

    </v-sheet>

    <!-- <v-dialog v-model="dialogHelp" scrollable max-width="800px">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="grey">mdi-help-circle-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <slot 
                        name="help" 
                        v-bind:monitor="monitor"
                    ></slot>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="dialogHelp = false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog> -->

</div>
</template>


<script>
export default {
    name: 'MonitorEmpty',
    props: ['label', 'icon', 'message'],
    data () {
        return {
            onoffSwitch: false,
            dialogHelp: false,
        }
    },
    computed: {
    },
    methods: {
        // Init
        init () {
            this.$emit('init');
        },
    },
    mounted () {
        this.init();
    },
    watch: {
    },    
}
</script>


<style scoped>
</style>
