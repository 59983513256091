import store from '@/store'
import router from '@/router'
import User from '@/classes/User'
import Interface from '@/classes/Interface'

// import Api from '@/services/api'
// import Api from '@/services/api-mock'
import Api from '@/services/api-wrapper'

import Settings from '@/constants/settings'

class System {

    init (route) {
        let request = {};
        let token = User.getToken();

        if (route.query.debug === null || Settings.debug) {
            this.debugOn();
        } else {
            this.debugOff();
        }

        this.debug(`Version: ${store.state.system.version} (${store.state.system.built})`);
        this.debug(`API: ${Settings.apiBaseUrl}`);

        if (token) request.token = token;

        return Api.post('/system/init', request, false, {doAuth: false})
            .then(data => {
                store.commit('system/setName', data.system.name);
                store.commit('system/setSettings', data.system.settings);
                store.commit('system/setPlans', data.system.plans);
                data.user ? User.setLogin(data.user) : User.setLogout();
                store.commit('system/setReady');
                return data;
            });
    }

    setSysError (message) {
        store.commit('system/setSysError', message);
    }

// Debug functions

    debugOn () {
        console.log('System debug enabled');
        store.commit('system/debugOn');
    }

    debugOff () {
        store.commit('system/debugOff');
    }

    debug(...args) {
        if (store.state.system.debug) {
            console.log(...args);
        }
    }

// Plans

    getPlan (code) {
        let result = false;
        
        store.state.system.plans.forEach(plan => {
            if (plan.code == code) result = plan;
        });

        return result;
    }

// Location redirect functions

    saveRedirect (path) {
        localStorage.setItem('to_route', path);
    }

    redirectToSaved (dflt = false) {
        let to = localStorage.getItem('to_route') || dflt;

        if (to) {
            localStorage.removeItem('to_route');
            router.push(to);
        }
    }

    redirectToLogin () {
        router.push({ name: 'login' });
    }

    redirectTo (to) {
        router.push(to);
    }

// API response handling

    apiDecline(error, handler = false) {
        if ([401].includes(error.code)) {
            User.setLogout();
            this.redirectToLogin();
            Interface.alertWarning('Session expired, please login again...');
        } else if ([402].includes(error.code)) {
            Interface.planErrOn();
        } else if (handler) {
            handler(error.message);
        }

        return error;
    }

// Load script

    loadScript (src) {
        return new Promise ((resolve, reject) => {
            let script = document.querySelector('script[src="' + src + '"]');

            if (!script) {
                this.debug('Loading script: ', src);

                script = document.createElement('script');
                script.async = true;
                script.src = src;
                script.onload = () => {
                    resolve();
                };
                script.onerror = () => {
                    reject();
                };
                script.onabort = () => {
                    reject();
                };

                document.head.appendChild(script);
            } else {
                this.debug('Script already loaded: ', src);
                resolve();
            }
        });
    }

}


export default new System();