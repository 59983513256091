import Vue from 'vue';
import VueRouter from 'vue-router';

import System from '@/classes/System'
import Settings from '@/constants/settings'

import store from '@/store'

import Login from '@/components/Login'
import Register from '@/components/Register'
import Remindpwd from '@/components/Remindpwd'
import Resetpwd from '@/components/Resetpwd'
import Confirm from '@/components/Confirm'
import Websites from '@/components/Websites'
import MonitorSite from '@/components/monitoring/MonitorSite'
import MonitorPage from '@/components/monitoring/MonitorPage'
import Dashboard from '@/components/Dashboard'
import Events from '@/components/Events'
import Reports from '@/components/Reports'
import Account from '@/components/Account'
import Support from '@/components/Support'
import Terms from '@/components/Terms'

Vue.use(VueRouter);

function user() {
    return !!store.state.user.data.email;
}

function countSites() {
    return store.state.user.sites.length;
}

function loggedIn(to, from, next) {
    if (!user()) {
        System.debug(`Route [${to.fullPath}][loggedIn]: denied`);
        System.saveRedirect(to.fullPath);
        next({name: 'login'});
    } else if (['dashboard', 'events', 'reports'].includes(to.name) && !countSites()) {
        System.debug(`Route [${to.fullPath}][loggedIn]: allowed`);
        next({name: 'websites'});
    } else {
        System.debug(`Route [${to.fullPath}][loggedIn]: allowed`);
        next();
    }  
}

function loggedOut(to, from, next) {
    if (user()) {
        System.debug(`Route [${to.fullPath}][loggedOut]: denied`);
        next({name: 'websites'});
    } else {
        System.debug(`Route [${to.fullPath}][loggedOut]: allowed`);
        next();
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {name: 'websites'},
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: loggedOut,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        beforeEnter: loggedOut,
    },
    {
        path: '/remindpwd',
        name: 'remindpwd',
        component: Remindpwd,
        beforeEnter: loggedOut,
    },
    {
        path: '/resetpwd',
        name: 'resetpwd',
        component: Resetpwd,
        beforeEnter: loggedOut,
    },
    {
        path: '/confirm/:regId/:code',
        name: 'confirm',
        component: Confirm,
        beforeEnter: loggedOut,
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        beforeEnter: loggedOut,
    },
    {
        path: '/websites/site/:id',
        name: 'monitor-site',
        component: MonitorSite,
        beforeEnter: loggedIn,
    },
    {
        path: '/websites/page/:id',
        name: 'monitor-page',
        component: MonitorPage,
        beforeEnter: loggedIn,
    },
    {
        path: '/websites',
        name: 'websites',
        component: Websites,
        beforeEnter: loggedIn,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: loggedIn,
    },
    {
        path: '/account',
        name: 'account',
        component: Account,
        beforeEnter: loggedIn,
    },
    {
        path: '/support',
        name: 'support',
        component: Support,
        beforeEnter: loggedIn,
    },
    {
        path: '/events',
        name: 'events',
        component: Events,
        beforeEnter: loggedIn,
    },
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
        beforeEnter: loggedIn,
    },
    {
        path: '*',
        redirect: {name: 'home'},
    },
]

export default new VueRouter({
    routes,
    mode: (Settings.useNiceUrl ? 'history' : 'hash'),
    // base: Settings.appBaseUrl,
});