<template>
<div>
    <Monitor
        :iid="site.id"
        label="Certificate Monitor"
        offq="Do you want to turn off website certificate monitoring?"
        code="ssl"
        :options="optionsForm"
        :form="$refs.optionsForm"
        v-on:enabled="$emit('enabled', $event)"
        v-on:status="$emit('status', $event)"
        v-on:init="$emit('init')"
        v-on:options="resetOptions($event)"
    >
        <template v-slot:details="{monitor}">
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Issuer:</span>{{monitor.data.certIssuer}}</p>
            <p class="text-caption mb-0" v-if="monitor.data.certExpiry"><span class="font-weight-medium mr-2">Expires:</span>{{humandate(monitor.data.certExpiry)}} (in {{monitor.data.certDaysExpiry}} days)</p>
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Domain:</span>{{monitor.data.certDomain}}</p>
            <div v-if="monitor.data.certAltDomains && monitor.data.certAltDomains.length">
                <p class="text-caption mb-0"><a href="#" @click.prevent="showAltDomains = !showAltDomains">Alternative domains</a></p>
                <v-expand-transition>
                    <div v-if="showAltDomains">
                        <p v-for="domain in monitor.data.certAltDomains" :key="domain" class="text-caption mb-0">{{domain}}</p>
                    </div>
                </v-expand-transition>
            </div>
        </template>

        <template v-slot:options>
            <v-form ref="optionsForm">
                <v-subheader class="pl-0">Check frequency:</v-subheader>
                <v-slider
                    v-model="interval"
                    :max="intervalItems.length - 1"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    prepend-icon="mdi-timer"
                    :messages="intervalItems[interval] + planWarning"
                    :rules="[
                        (v) => validMinInt(v) || 'Interval error',
                    ]"
                ></v-slider>
                <v-subheader class="pl-0 mt-6">Notification frequency:</v-subheader>
                <v-select
                    v-model="frequency"
                    :items="frequencyItems"
                    item-text="label"
                    item-value="frequency"
                    dense
                    prepend-icon="mdi-bell-outline"
                ></v-select>
                <v-subheader class="pl-0 mt-6">Expiration warning:</v-subheader>
                <v-select
                    v-model="warning"
                    :items="warningItems"
                    item-text="label"
                    item-value="days"
                    dense
                    prepend-icon="mdi-alert-outline"
                ></v-select>
                <v-subheader class="pl-0 mt-6">Expiration alerts:</v-subheader>
                <v-select
                    v-model="alert"
                    :items="alertItems"
                    item-text="label"
                    item-value="days"
                    dense
                    prepend-icon="mdi-alert"
                ></v-select>
            </v-form>
        </template>

        <template v-slot:help>
            <p class="text-body-1 text-center">Certificate Monitor</p>
            <p>
                If your website’s full URL in the browser starts with “https://” then it means that communication between browser and web server is encrypted using SSL/TLS mechanism. 
                This allows transmitted data, including passwords and credit card numbers, to be protected from interception on the way from the browser to the server. 
                Websites that do not use this encryption and which URL starts with “http://” (without ‘s’ at the end) are vulnerable for man-in-the middle attacks and sensitive data interception. 
                Encryption is ensured by a special SSL certificate installed on the web server. This certificate not only allows to encrypt the data, but also serves as a digital signature 
                and proof that the user is on a genuine verified website marking this website as trusted.
            </p>
            <p>
                If your website’s SSL certificate becomes invalid or expired, users visiting your website will receive an alert that this website cannot be trusted and the browser will terminate the connection. 
                SSL certificate failures are one of the most common reasons for website unavailability.
            </p>
            <p>
                Our Certificate Monitor checks your website's SSL certificate and alerts you when there is a problem, so you could take immediate action and minimize the downtime of your website. 
                Our service will also warn you before your SSL certificate is about to expire, so that it can be renewed in time.
            </p>
            <p>
                Monitor can be controlled with following settings:
            </p>
            <p class="mb-0 font-weight-bold">Check frequency</p>
            <p>How often shall we check your SSL certificate.</p>
            <p class="mb-0 font-weight-bold">Notification frequency</p>
            <p>
                How often shall we send you alerts about discovered problems.
                <ul>
                    <li>Every check - We will send you notification every time we discover a problem even if this problem was already reported after the previous check.</li>
                    <li>Once per problem - We will send you notification once when a problem is first discovered or problem severity increases compared to the previous check.</li>
                    <li>Once in an hour / Once in a day - Notifications about the same problem will be sent no more often than the selected period. New notification may be sent if problem severity increases compared to the previous check.</li>
                    <li>Don’t send notifications - We will not send any notifications about this event.</li>
                </ul>
            </p>
            <p class="mb-0 font-weight-bold">Expiration warning</p>
            <p>When shall we send the first SSL certificate expiration warning.</p>
            <p class="mb-0 font-weight-bold">Expiration alerts</p>
            <p>When shall we start sending everyday SSL certificate expiration alerts.</p>
        </template>

    </Monitor>

</div>
</template>


<script>
import {mapState} from 'vuex'

import Monitoring from '@/classes/Monitoring'
import Dates from '@/classes/Dates'

import Monitor from '@/components/monitoring/Monitor'
import System from '@/classes/System'

export default {
    name: 'MonitorSsl',
    props: ['site'],
    components: {
        Monitor,
    },
    data () {
        return {
            // Options
            interval: 3,
            intervalItems: [
                'Every minute',
                'Every 5 minutes',
                'Every 15 minutes',
                'Every 30 minutes',
                'Every hour',
                'Every 4 hours',
                'Every 12 hours',
                'Once a day',
            ],
            intervals: {
                0:       1 * 60,
                1:       5 * 60,
                2:      15 * 60,
                3:      30 * 60,
                4:      60 * 60,
                5:  4 * 60 * 60,
                6: 12 * 60 * 60,
                7: 24 * 60 * 60,
            },
            frequency: 2,   
            frequencyItems: [
                {frequency: 1, label: "Every check"},
                {frequency: 2, label: "Once per problem"},
                {frequency: 3, label: "Once in an hour"},
                {frequency: 4, label: "Once in a day"},
                {frequency: 0, label: "Don't send notifications"},
            ],
            warning: 30,
            warningItems: [
                {days: 60, label: "2 months before expiry"},
                {days: 30, label: "1 month before expiry"},
                {days: 14, label: "2 weeks before expiry"},
                {days: 7,  label: "1 week before expiry"},
            ],
            alert: 7,   
            alertItems: [
                {days: 7, label: "1 week before expiry"},
                {days: 5, label: "5 days before expiry"},
                {days: 3, label: "3 days before expiry"},
                {days: 1, label: "1 day before expiry"},
            ],
            // Other
            showAltDomains: false,
            plan: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        optionsForm () {
            return {
                intervalCheck: this.intervals[this.interval],
                alertFreq: this.frequency,
                daysAlertFirst: this.warning,
                daysAlertMass: this.alert,
            }
        },
        planWarning () {
            if (this.plan && this.intervals[this.interval] < this.plan.limits.minInt) {
                return ' (not available in your subscription plan)'
            } else {
                return ''
            }
        },
    },
    methods: {
        init () {
            this.plan = System.getPlan(this.user.data.plan);
        },
        resetOptions (options) {
            this.interval = Object.keys(this.intervals).find(key => this.intervals[key] == options.intervalCheck) || 3;
            this.frequency = options.alertFreq;
            this.warning = options.daysAlertFirst;
            this.alert = options.daysAlertMass;
        },
        humandate (date) {
            return Dates.human(date);
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        validMinInt (v) {
            return this.intervals[v] >= this.plan.limits.minInt;
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        'site': 'init',
    },    
}
</script>


<style scoped>
</style>
