<template>
<div>
    <!-- <Notifications :items="notifications" /> -->

    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
             <v-btn icon @click.stop="drawerRight = !drawerRight" v-bind="attrs" v-on="on"><v-icon>mdi-server</v-icon></v-btn>
        </template>    
        <span>Websites List</span>
    </v-tooltip>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
             <v-btn icon @click.stop="logout()" v-bind="attrs" v-on="on"><v-icon>mdi-exit-to-app</v-icon></v-btn>
        </template>    
        <span>Logout</span>
    </v-tooltip>

</div>
</template>


<script>
import { mapState } from 'vuex'
import User from '@/classes/User'

// import Notifications from '@/components/system/Notifications'

export default {
    name: 'Bar',
    components: {
        // Notifications,
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.user.notifications,
            sites: state => state.user.sites,
        }),
        drawerRight: {
            get () {
                return this.$store.state.system.sites.show;
            },
            set (v) {
                if (v) {
                    this.$store.commit('system/sitesOn');
                } else {
                    this.$store.commit('system/sitesOff');
                }
            }
        },
    },
    methods: {
        logout () {
            User.logout();
            this.$router.push({ name: 'login' });
        }
    },

}
</script>


<style scoped>
</style>
