// import {SITE_STATUS} from '@/constants/common'
import Dates from '@/classes/Dates'
// import Api from '@/services/api'
// import Api from '@/services/api-mock'
import Api from '@/services/api-wrapper'

class Events {

// Helper functions 

// API functions 

    date (date) {
        return Api.post('/secure/events/date', {from: Dates.daystart(date), to: Dates.dayend(date)}, false, {doLoading: false});
    }

    dates (severity) {
        return Api.post('/secure/events/dates', {severity}, false, {doLoading: false});
    }


}


export default new Events();