import Fingerprint from '@/classes/Fingerprint'
import CryptoJS from 'crypto-js'

// var CryptoJS = require("crypto-js");

class Encrypt {

    constructor () {
        this.fp = String(Fingerprint());
    }

    enc (cleartext) {
        // console.log(`Encrypt [${cleartext}] fp [${this.fp}]`);
        return CryptoJS.AES.encrypt(String(cleartext), this.fp).toString();
    }
    
    dec (enctext) {
        // console.log(`Decrypt [${enctext}]`);
        try {
            return CryptoJS.AES.decrypt(enctext, this.fp).toString(CryptoJS.enc.Utf8);    
        } catch (error) {
            return false;
        }
    }

}

export default new Encrypt();