<template>
<v-list dense>
    <div class="d-flex">
        <v-subheader>My Websites</v-subheader>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="addURL()"><v-icon>mdi-plus</v-icon></v-btn>
            </template>    
            <span>Add New URL</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-lg-3" v-bind="attrs" v-on="on" @click.stop="getSites()" :loading="loading"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>    
            <span>Refresh</span>
        </v-tooltip>
        <v-btn icon class="hidden-lg-and-up mr-3" @click.stop="drawerRight = !drawerRight"><v-icon>mdi-close</v-icon></v-btn>
    </div>

    <div v-if="!sites.length" class="ma-5 text-center">
        <v-icon color="grey lighten-3" size="50">mdi-file-hidden</v-icon>
        <p class="overline grey--text text--lighten-2">Empty</p>
    </div>    
    
    <template v-for="site in sites">

        <v-list-group :key="site.id" :value="site.opened">

            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ site.url }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>

            <template v-slot:prependIcon>
                <v-badge dot :color="color(site.statusGroup)">
                    <v-icon>mdi-domain</v-icon>
                </v-badge>
            </template>

            <template>
                <v-list-item :to="{name: 'monitor-site', params: {id: site.id}}" color="primary" link>
                    <v-list-item-icon class="ml-4 mr-4">
                        <v-badge dot :color="color(site.status)">
                            <v-icon class="grey--text text--lighten-1">mdi-server-network</v-icon>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="">Domain and Server</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="page in site.pages" :key="page.id" :to="{name: 'monitor-page', params: {id: page.id}}" color="primary" link>
                    <v-list-item-icon class="ml-4 mr-4">
                        <v-badge dot :color="color(page.status)">
                            <v-icon class="grey--text text--lighten-1">mdi-file-outline</v-icon>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{pageUrl(page.url)}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

        </v-list-group>

    </template>
</v-list>
</template>


<script>
import { mapState } from 'vuex'

import System from '@/classes/System'
import Monitoring from '@/classes/Monitoring'
import Interface from "@/classes/Interface";
import Sites from '@/classes/Sites'


export default {
    name: 'Menu',
    data () {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        isMobile () {
            return this.$vuetify.breakpoint.mobile;
        },
        drawerRight: {
            get () {
                return this.sites.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/sitesOn');
                } else {
                    this.$store.commit('system/sitesOff');
                }
            }
        },
    },
    methods: {
        async getSites() {
                this.loading = true;
                
                return Sites.get()
                    .catch(error => {
                        if (this.isMobile) this.drawerRight = !this.drawerRight;
                        System.apiDecline(error, Interface.alertError);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
        },
        addURL () {
            if (this.$route.name == 'websites') {
                window.$app.$emit('open-addurl')
            } else {
                this.$router.push({name: 'websites', query: {add: true}});
            }
            if (this.isMobile) this.drawerRight = !this.drawerRight;
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        pageUrl (url) {
            return Sites.pageUrl(url);
        },
    },

}
</script>


<style scoped>
</style>
