var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"mb-3",attrs:{"width":"100%","elevation":"1","rounded":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-7"},[_c('p',{staticClass:"mt-4 grey--text text--darken-1"},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"grey"}},[_vm._v("mdi-file-outline")]),_vm._v("Pages:")],1)]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","static":"","top":"","right":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogHelp = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Description")])])],1),_c('div',{staticClass:"px-5 pt-2 pb-5 mb-10"},[_c('v-simple-table',{staticClass:"grey lighten-5 rounded-0",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.site.pages),function(page){return _c('tr',{key:page.id},[_c('td',[_c('router-link',{staticClass:"teal--text text--darken-2",attrs:{"to":{name: 'monitor-page', params: {id: page.id}}}},[_c('code',[_vm._v(_vm._s(_vm.pageUrl(page.url)))])])],1),_c('td',{staticClass:"text-center",staticStyle:{"width":"30%"},attrs:{"nowrap":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":_vm.color(page.statusUptime)},on:{"click":function($event){return _vm.navToPageMon(page.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document-outline")])]}}],null,true)},[_c('span',[_vm._v("Uptime Status: "+_vm._s(_vm.descr(page.statusUptime)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":_vm.color(page.statusHtml)},on:{"click":function($event){return _vm.navToPageMon(page.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-code-outline")])]}}],null,true)},[_c('span',[_vm._v("HTML Status: "+_vm._s(_vm.descr(page.statusHtml)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":_vm.color(page.perfMobile)},on:{"click":function($event){return _vm.navToPageMon(page.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cellphone")])]}}],null,true)},[_c('span',[_vm._v("Mobile Performance: "+_vm._s(_vm.descr(page.perfMobile)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.color(page.perfDesktop)},on:{"click":function($event){return _vm.navToPageMon(page.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-monitor")])]}}],null,true)},[_c('span',[_vm._v("Desktop Performance: "+_vm._s(_vm.descr(page.perfDesktop)))])])],1)])}),0)]},proxy:true}])})],1)]),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dialogHelp),callback:function ($$v) {_vm.dialogHelp=$$v},expression:"dialogHelp"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"py-3 text-center",staticStyle:{"width":"100%"}},[_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v("mdi-help-circle-outline")])],1)]),_c('v-card-text',[_c('div',[_c('p',{staticClass:"text-body-1 text-center"},[_vm._v("Pages Monitor")]),_c('p',[_vm._v(" This widget displays the status of all added pages of this website. To add a new page, go to My Websites main screen and add the full URL of your page. Widget table displays status of following page level monitors: Page Uptime Monitor, HTML Validator, Mobile and Desktop Performance. ")]),_c('p',[_vm._v(" Click on each page to go to the page monitoring section to see the details. ")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","text":""},on:{"click":function($event){_vm.dialogHelp = false}}},[_vm._v("Close")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }