<template>
<v-app>

    <v-app-bar app clipped-left clipped-right flat elevation="1">
        <div class="d-flex align-center">
            <v-app-bar-nav-icon v-if="user" @click.stop="drawerLeft = !drawerLeft"></v-app-bar-nav-icon>
            <Logo width="155" />
            <!-- <span class="text-h6 grey--text hidden-xs-only">rankchart</span> -->
        </div>
        <v-spacer></v-spacer>
        <Bar v-if="user" />
    </v-app-bar>

    <v-navigation-drawer v-if="user" v-model="drawerLeft" clipped app>
        <Head />
        <Menu />
    </v-navigation-drawer>

    <v-navigation-drawer v-if="user" v-model="drawerRight" clipped app right width="350px">
        <SitesMenu />
    </v-navigation-drawer>

    <v-main class="grey lighten-4">
        <v-container fluid>
        <!-- <v-container> -->
            <Loading />
            <Alert />

            <SystemError v-if="syserror" :text="syserror" />
            <router-view v-else-if="ready"></router-view>

            <Snack />
            <PlanError />
        </v-container>
    </v-main>

</v-app>
</template>

<script>
import { mapState } from 'vuex';
import System from './classes/System';

import Logo from '@/components/system/Logo';
import Menu from '@/components/system/Menu';
import SitesMenu from '@/components/system/SitesMenu';
import Head from '@/components/system/Head';
import Bar from '@/components/system/Bar';
import Alert from '@/components/system/Alert';
import Snack from '@/components/system/Snack';
import Loading from '@/components/system/Loading';
import PlanError from '@/components/system/PlanError';
import SystemError from '@/components/system/SystemError';

export default {
    name: 'App',
    components: {
        Logo,
        Menu,
        SitesMenu,
        Head,
        Bar,
        Alert,
        Snack,
        Loading,
        SystemError,
        PlanError,
    },
    data () {
        return {
            drawerLeft: undefined,
        }
    },
    computed: {
        ...mapState({
            user: state => !!state.user.data.email,
            system: state => state.system,
            loading: state => state.system.loading,
            syserror: state => state.system.syserror,
            ready: state => state.system.ready,
            sites: state => state.system.sites,
        }),
        drawerRight: {
            get () {
                return this.sites.show;
            },
            set (v) {
                if (v) {
                    this.$store.commit('system/sitesOn');
                } else {
                    this.$store.commit('system/sitesOff');
                }
            }
        },
    },
    created () {
        System.init(this.$route)
            .then(data => {
                if (data.user) {
                    System.redirectToSaved('/');
                }
            })
            .catch(error => {
                System.setSysError('Failed to initialize the application: ' + error.message);
            });
    },
};
</script>

<style scoped>
.container {
    min-height: 100%;
}
</style>
