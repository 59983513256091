import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import Settings from '@/constants/settings'

console.log('gg: ', Settings.captchaSiteKey);
Vue.use(VueReCaptcha, { 
    // siteKey: '6LccnHQaAAAAAC3Tr44L6EeDLxAE0JzuZbAkOInC',
    siteKey: Settings.captchaSiteKey,
    loaderOptions: {
        autoHideBadge: true,
    }
});
