<template>
<div>
    <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
        <v-sheet elevation="1" rounded="lg" width="400">
            <div class="ma-8 pt-10">
                <v-form ref="registerForm">
                    <v-text-field 
                        label="Email"
                        hint="Email will be used for login"
                        ref="email"
                        type="email"
                        counter="100"
                        v-model="email"
                        autocomplete="username"
                        append-icon="mdi-account"
                        :rules="[
                            (v) => !!v || 'Email is required',
                            (v) => /.+@.+/.test(v) || 'Email must be valid',    
                            (v) => v.length <= 100 || 'Email too long',                            
                        ]"
                        required
                    ></v-text-field>
                    <v-text-field 
                        label="Your Name"
                        hint="Tell us how to call you?"
                        ref="name"
                        counter="100"
                        v-model="name"
                        append-icon="mdi-comment-account"
                        :rules="[
                            (v) => !!v || 'Enter your name',
                            (v) => v.length <= 100 || 'Name too long',
                        ]"
                        required
                    ></v-text-field>
                    <v-text-field 
                        label="Password" 
                        hint="Choose your password"
                        :type="show ? 'text' : 'password'"
                        v-model="password"
                        counter="100"
                        autocomplete="new-password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                            (v) => !!v || 'Enter your password',
                            (v) => v.length <= 100 || 'Password too long',
                        ]"
                        required
                        @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field 
                        :disabled="show"
                        ref="password2"
                        label="Password Confirmation" 
                        hint="Confirm your password"
                        type="password"
                        counter="100"
                        autocomplete="new-password"
                        v-model="password2"
                        append-icon="mdi-eye-check"
                        :rules="[() => (show || password == password2) || 'Passwords do not match']"
                        required
                    ></v-text-field>
                    <v-checkbox
                        v-model="agree"
                        :rules="[() => (agree) || 'You must agree with service terms']"
                    >
                        <template v-slot:label>
                            Accept service<router-link :to="{name: 'terms', query: {back: 'register'}}" class="mx-1">terms and privacy</router-link> policy
                        </template>
                    </v-checkbox>                    
                    <v-btn
                        class="mt-6" 
                        type="submit"
                        color="primary" 
                        block
                        :loading="loading"
                        @click.prevent="register()"
                    >Create New Account</v-btn>
                </v-form>
                <p class="mt-5"><router-link :to="{ name: 'login' }" class="text-body-2">Login with existing account</router-link></p>
            </div>
        </v-sheet>
    </div>
    <div class="text-center mt-10">
        <p class="mt-5 text-caption">By using this service you agree with <router-link :to="{name: 'terms', query: {back: 'register'}}">Terms and Privacy</router-link> policy. This website is using local storage to save the unsensitive session data.</p>
        <p class="mt-5 text-caption">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
    </div>
</div>
</template>


<script>
import Interface from "@/classes/Interface";
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'Register',
    data () {
        return {
            username: '',
            password: '',
            password2: '',
            name: '',
            email: '',
            agree: false,
            token: '',
            show: false,
            loading: false,
        }
    },
    methods: {
        async register () {
            if (this.$refs.registerForm.validate()) {
                Interface.alertOff();
                this.loading = true;

                try {
                    await this.$recaptchaLoaded();
                    this.token = await this.$recaptcha('register');
                } catch (error) {
                    this.loading = false;
                    this.token = false;
                    Interface.alertError('reCAPTCHA validation failed');
                    return false;
                }

                return User.register(this.registerForm)
                    .then(() => {
                        this.loading = false;
                        Interface.alertSuccess('Check your email to complete the registration...');
                        System.redirectToLogin();
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$refs.registerForm.resetValidation()
                        Interface.alertError(error.message||'Registration failed');
                    })
            }
        },
        // hideCpt () {
        //     const recaptcha = this.$recaptchaInstance;
        //     recaptcha.hideBadge();
        // },
        // showCpt () {
        //     const recaptcha = this.$recaptchaInstance;
        //     recaptcha.showBadge();
        // },
    },
    computed: {
        // system () {
        //     return this.$store.state.system;
        // },
        registerForm () {
            return {
                username: this.username,
                password: this.password,
                name: this.name,
                email: this.email,
                token: this.token,
            }
        }
    },
    watch: {
        show (newv) {
            if (newv) {
                this.password2 = '';
                this.$refs.password2.resetValidation();
            }
        },
    },
    mounted () {
        Interface.alertOff();
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
