<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mb-3" style="position: relative">
        <p class="text-h5 font-weight-light text-center mb-2">Page Monitoring</p>
        <div v-if="site">
            <p class="text-body-2 text--secondary text-center mb-2">
                <a :href="page.urlFull" target="_blank">{{page.urlFull}}</a>
            </p>
            <p class="text-caption font-weight-light text--secondary text-center mb-2">
                {{enabled}} of {{total}} monitors enabled
            </p>
            <p class="text-center mb-0" v-if="ok || warnings || errors">
                <span v-if="ok" class="success--text mx-2"><v-icon color="success">mdi-checkbox-marked-circle</v-icon> {{ok}}</span>
                <span v-if="warnings" class="warning--text mx-2"><v-icon color="warning">mdi-alert-circle</v-icon> {{warnings}}</span>
                <span v-if="errors" class="error--text mx-2"><v-icon color="error">mdi-alert</v-icon> {{errors}}</span>
            </p>
        </div>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon absolute top left v-bind="attrs" v-on="on" @click.stop="$router.push({name: 'monitor-site', params: {id: page.siteID}})"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>    
            <span>Back to site</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
                <v-btn icon absolute top right style="margin-right: -4px" v-bind="attrs" v-on="on" @click.stop="dialogHelp = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
            </template>    
            <span>Description</span>
        </v-tooltip>

    </v-sheet>

    <div v-if="page">
        <MonitorUptime 
            :page="page"
            @status="updateStatus(monitors.uptime, 'statusUptime', $event)"
            @enabled="monitors.uptime.enabled = $event" 
        />
        <MonitorEmpty 
            label="HTML Validator"
            icon="mdi-dna"
            message="Under construction, coming soon..."
        />
        <MonitorEmpty 
            label="Mobile Performance"
            icon="mdi-dna"
            message="Under construction, coming soon..."
        />
        <MonitorEmpty 
            label="Desktop Performance"
            icon="mdi-dna"
            message="Under construction, coming soon..."
        />

    </div>

    <v-dialog v-model="dialogHelp" scrollable max-width="800px">
        <v-card>
            <v-card-title>
                <div class="py-3 text-center" style="width: 100%">
                    <v-icon large color="grey">mdi-help-circle-outline</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <p class="text-body-1 text-center">Page Level Monitoring</p>
                    <p>
                        There are two types of monitors in our system: Server level and Page level. Server level monitors measure characteristics related to the whole website and are common to all pages of this website, such as domain name or SSL certificate. 
                        Meanwhile page level monitors check characteristics of each individual page, such as page performance or HTML validation errors.
                    </p>
                    <p>
                        This section represents web page level monitors. We offer following checks to make sure your page is always available and high rated in search engines:
                        <ul>
                            <li>Uptime Monitor - Various checks related to page availability, content and load time.</li>
                            <li>HTML Validator - Constant page HTML validation to ensure high search engine rank.</li>
                            <li>Mobile Performance - Monitors how well your website works on mobile devices.</li>
                            <li>Desktop Performance - Monitors how well your website works on desktop computers and laptops.</li>
                        </ul>
                    </p>
                    <p>
                        Press Description button on each widget to read more about each of the monitors and their options.
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" text @click="dialogHelp = false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>
import {mapState} from 'vuex'

import {SITE_STATUS} from '@/constants/common'
import Monitoring from '@/classes/Monitoring'
import Sites from '@/classes/Sites'
// import Interface from '@/classes/Interface'

import MonitorUptime from '@/components/monitoring/MonitorUptime'
import MonitorEmpty from '@/components/monitoring/MonitorEmpty'

export default {
    name: 'MonitorPage',
    components: {
        MonitorUptime,
        MonitorEmpty,
    },
    data () {
        return {
            page: false,
            site: false,
            total: 4,
            monitors: {
                uptime: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
                html: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
                mobile: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
                desktop: {
                    enabled: false,
                    status: SITE_STATUS.UNKNOWN,
                },
            },
            dialogHelp: false,
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        pageID () {
            return Number(this.$route.params.id) || false;
        },
        enabled () {
            return Monitoring.statsPage(this.monitors).enabled || 0;
        },
        ok () {
            return Monitoring.statsPage(this.monitors).ok || 0;
        },
        warnings () {
            return Monitoring.statsPage(this.monitors).warning || 0;
        },
        errors () {
            return Monitoring.statsPage(this.monitors).error || 0;
        },
    },
    methods: {
        init () {
            this.$vuetify.goTo(0);
            // this.total = 0;
            this.page = Sites.getPageEntryGlobal(this.sites, this.pageID);
            this.site = Sites.getSiteEntry(this.sites, this.page.siteID);
        },
        updateStatus (monitor, field, status) {
            monitor.status = status;
            this.page[field] = status;
            Sites.computeSiteData(this.site);
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        'pageID': 'init',
    },    
}
</script>


<style scoped>
</style>
