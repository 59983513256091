const state = () => ({
    name: undefined,
    version: '0.9.12',
    built: '25-Jan-2022',
    ready: false,
    loading: false,
    syserror: false,
    planerror: false,
    settings: {
        allowSavedSession: true,
        googleClientId: '',
        fbAppId: '',
        captchaSiteKey: '',
        userNameColor: '#757575',
        systemNameColor: '#424242',
    },
    plans: [],
    alert: {
        show: false,
        type: undefined,
        text: undefined,
    },
    snack: {
        show: false,
        type: undefined,
        text: undefined,
    },
    sites: {
        show: null,
    },
    debug: false,
})

const getters = {};

const mutations = {
    loadingOn (state) {
        state.loading = true;
    },
    loadingOff (state) {
        state.loading = false;
    },
    setSysError (state, syserror) {
        state.syserror = syserror;
    },
    setPlanError (state, planerror) {
        state.planerror = planerror;
    },
    alertOn (state, alert) {
        Object.assign(state.alert, alert);
        state.alert.show = true;
    },
    alertOff (state) {
        state.alert.show = false;
    },
    snackOn (state, snack) {
        Object.assign(state.snack, snack);
        state.snack.show = true;
    },
    snackOff (state) {
        state.snack.show = false;
    },
    setName (state, name) {
        state.name = name;
    },
    setSettings (state, settings) {
        Object.assign(state.settings, settings);
    },
    setPlans (state, plans = []) {
        state.plans = plans;
    },
    setReady (state, ready = true) {
        state.ready = ready;
    },
    sitesOn (state) {
        state.sites.show = true;
    },
    sitesOff (state) {
        state.sites.show = false;
    },
    debugOn (state) {
        state.debug = true;
    },
    debugOff (state) {
        state;
        // state.debug = false;
    },
};

const actions = {};

// GET_TODO: async (context, payload) => {
//     let {data} = await Axios.get('http://yourwebsite.com/api/todo');
//     context.commit('SET_TODO', data);
//   },

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }