// import Api from '@/services/api'
// import Api from '@/services/api-mock'
import Api from '@/services/api-wrapper'

import { SITE_STATUS } from '@/constants/common'

class Monitoring {

// Helper functions

    colorByStatus (status, hex = false) {
        if (status == SITE_STATUS.OK) {
            return hex ? '#4CAF50' : 'success';
        } else if (status == SITE_STATUS.WARNING) {
            return hex ? '#FFC107' : 'warning';
        } else if (status == SITE_STATUS.ERROR) {
            return hex ? '#FF5252' : 'error';
        } else {
            return hex ? '#9E9E9E' : 'grey';
        }
    }

    descrByStatus (status) {
        if (status == SITE_STATUS.OK) {
            return 'OK';
        } else if (status == SITE_STATUS.WARNING) {
            return 'Warning';
        } else if (status == SITE_STATUS.ERROR) {
            return 'Error';
        } else {
            return 'Untracked';
        }
    }

    iconByStatus (status) {
        if (status == SITE_STATUS.OK) {
            return 'mdi-checkbox-marked-circle';
        } else if (status == SITE_STATUS.WARNING) {
            return 'mdi-alert-circle-outline';
        } else if (status == SITE_STATUS.ERROR) {
            return 'mdi-alert';
        } else {
            return 'mdi-help-circle';
        }
    }

    iconByMonitor (monitor) {
        if (monitor == 'domain') {
            return 'mdi-domain';
        } else if (monitor == 'ssl') {
            return 'mdi-certificate';
        } else if (monitor == 'ping') {
            return 'mdi-gauge';
        } else if (monitor == 'uptime') {
            return 'mdi-file-document-outline';
        } else if (monitor == 'html') {
            return 'mdi-file-code-outline';
        } else if (monitor == 'mobile') {
            return 'mdi-cellphone';
        } else if (monitor == 'desktop') {
            return 'mdi-monitor';
        } else {
            return 'mdi-help-circle';
        }
    }

    descrByMonitor (monitor) {
        if (monitor == 'domain') {
            return 'Domain monitor';
        } else if (monitor == 'ssl') {
            return 'Certificate monitor';
        } else if (monitor == 'ping') {
            return 'Ping monitor';
        } else if (monitor == 'uptime') {
            return 'Page uptime monitor';
        } else if (monitor == 'html') {
            return 'HTML validity monitor';
        } else if (monitor == 'mobile') {
            return 'Mobile performance';
        } else if (monitor == 'desktop') {
            return 'Desktop performance';
        } else {
            return 'Unknown';
        }
    }

    httpColor (code, hex = false) {
        if (+code < 300) {
            return hex ? '#4CAF50' : 'success';
        } else if (+code < 400) {
            return hex ? '#FFC107' : 'warning';
        } else if (+code < 600) {
            return hex ? '#FF5252' : 'error';
        } else {
            return hex ? '#9E9E9E' : 'grey';
        }
    }

    valueColor (value, warn, alert, hex = false) {
        if (+value < warn) {
            return hex ? '#4CAF50' : 'success';
        } else if (+value < alert) {
            return hex ? '#FFC107' : 'warning';
        } else if (+value >= alert) {
            return hex ? '#FF5252' : 'error';
        } else {
            return hex ? '#9E9E9E' : 'grey';
        }
    }

// Dashboard stats functions

    statsSites (sites) {
        let result = {
            all: sites.length,
            allpages: 0,
            status: {
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            pages: {
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            ping: {
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            ssl: {
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            domain: {
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            html: {
                all: 0,
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            mobile: {
                all: 0,
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
            desktop: {
                all: 0,
                unknown: 0,
                ok: 0,
                warning: 0,
                error: 0,
            },
        }

        sites.forEach(site => {
            // Global Status
            if (site.statusGroup == SITE_STATUS.UNKNOWN) {
                result.status.unknown++;
            } else if (site.statusGroup == SITE_STATUS.OK) {
                result.status.ok++;
            } else if (site.statusGroup == SITE_STATUS.WARNING) {
                result.status.warning++;
            } else if (site.statusGroup == SITE_STATUS.ERROR) {
                result.status.error++;
            }

            // Ping
            if (site.statusPing == SITE_STATUS.UNKNOWN) {
                result.ping.unknown++;
            } else if (site.statusPing == SITE_STATUS.OK) {
                result.ping.ok++;
            } else if (site.statusPing == SITE_STATUS.WARNING) {
                result.ping.warning++;
            } else if (site.statusPing == SITE_STATUS.ERROR) {
                result.ping.error++;
            }

            // Ssl
            if (site.statusSsl == SITE_STATUS.UNKNOWN) {
                result.ssl.unknown++;
            } else if (site.statusSsl == SITE_STATUS.OK) {
                result.ssl.ok++;
            } else if (site.statusSsl == SITE_STATUS.WARNING) {
                result.ssl.warning++;
            } else if (site.statusSsl == SITE_STATUS.ERROR) {
                result.ssl.error++;
            }

            // Domain
            if (site.statusDomain == SITE_STATUS.UNKNOWN) {
                result.domain.unknown++;
            } else if (site.statusDomain == SITE_STATUS.OK) {
                result.domain.ok++;
            } else if (site.statusDomain == SITE_STATUS.WARNING) {
                result.domain.warning++;
            } else if (site.statusDomain == SITE_STATUS.ERROR) {
                result.domain.error++;
            }

            // Pages
            site.pages.forEach(page => {
                result.allpages++;

                // Status
                if (page.status == SITE_STATUS.UNKNOWN) {
                    result.pages.unknown++;
                } else if (page.status == SITE_STATUS.OK) {
                    result.pages.ok++;
                } else if (page.status == SITE_STATUS.WARNING) {
                    result.pages.warning++;
                } else if (page.status == SITE_STATUS.ERROR) {
                    result.pages.error++;
                }

                // Html
                if (page.statusHtml == SITE_STATUS.UNKNOWN) {
                    result.html.unknown++;
                } else if (page.statusHtml == SITE_STATUS.OK) {
                    result.html.ok++;
                } else if (page.statusHtml == SITE_STATUS.WARNING) {
                    result.html.warning++;
                } else if (page.statusHtml == SITE_STATUS.ERROR) {
                    result.html.error++;
                }

                // Mobile
                if (page.perfMobile == SITE_STATUS.UNKNOWN) {
                    result.mobile.unknown++;
                } else if (page.perfMobile == SITE_STATUS.OK) {
                    result.mobile.ok++;
                } else if (page.perfMobile == SITE_STATUS.WARNING) {
                    result.mobile.warning++;
                } else if (page.perfMobile == SITE_STATUS.ERROR) {
                    result.mobile.error++;
                }

                // Desktop
                if (page.perfDesktop == SITE_STATUS.UNKNOWN) {
                    result.desktop.unknown++;
                } else if (page.perfDesktop == SITE_STATUS.OK) {
                    result.desktop.ok++;
                } else if (page.perfDesktop == SITE_STATUS.WARNING) {
                    result.desktop.warning++;
                } else if (page.perfDesktop == SITE_STATUS.ERROR) {
                    result.desktop.error++;
                }

            })
    
        });

        return result;
    }

 // Site monitoring functions
    
    statsSite (monitors) {
        let result = {
            total: 3,
            enabled: 0,
            ok: 0,
            warning: 0,
            error: 0,
        }

        if (monitors.ping.enabled) result.enabled++;
        if (monitors.ssl.enabled) result.enabled++;
        if (monitors.domain.enabled) result.enabled++;

        if (monitors.ping.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.ping.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.ping.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        if (monitors.ssl.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.ssl.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.ssl.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        if (monitors.domain.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.domain.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.domain.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        return result;
    }

// Page monitoring functions

    statsPage (monitors) {
        let result = {
            total: 4,
            enabled: 0,
            ok: 0,
            warning: 0,
            error: 0,
        }

        if (monitors.uptime.enabled) result.enabled++;
        if (monitors.html.enabled) result.enabled++;
        if (monitors.mobile.enabled) result.enabled++;
        if (monitors.desktop.enabled) result.enabled++;

        if (monitors.uptime.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.uptime.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.uptime.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        if (monitors.html.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.html.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.html.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        if (monitors.mobile.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.mobile.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.mobile.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        if (monitors.desktop.status == SITE_STATUS.OK) {
            result.ok++;
        } else if (monitors.desktop.status == SITE_STATUS.WARNING) {
            result.warning++;
        } if (monitors.desktop.status == SITE_STATUS.ERROR) {
            result.error++;
        }

        return result;
    }

// API functions

    get (monitor, id) {
        return Api.post(`/secure/monitor/${monitor}/stats`, {id}, false, {doLoading: false});
    }

    on (monitor, id, options) {
        return Api.post(`/secure/monitor/${monitor}/on`, {id, options});
    }

    off (monitor, id) {
        return Api.post(`/secure/monitor/${monitor}/off`, {id});
    }

    hist (monitor, id, from = false, to = false, limit = false) {
        let data = {id};

        if (from) data.from = from;
        if (to) data.to = to;
        if (limit) data.limit = limit;

        return Api.post(`/secure/monitor/${monitor}/hist`, data);
    }

}


export default new Monitoring();