import Interface from '@/classes/Interface'
import System from '@/classes/System'
import User from '@/classes/User'
import ApiError from '@/classes/ApiError'
import axios from 'axios'

import Settings from '@/constants/settings'

class Api {

    constructor () {
        this.baseURL = Settings.apiBaseUrl;
    }

    // GET wrapper
    get (url, params = false, _settings = {}) {
        return this.request('get', url, params, false, 'json', _settings);
    }

    // POST wrapper
    post (url, data = false, params = false, _settings = {}) {
        return this.request('post', url, params, data, 'json', _settings);
    }

    // Generic request fnction
    request (method, url, params, data, type, _settings) {
        let config = {
            baseURL: this.baseURL,
            url: url,
            method: method,
            responseType: type,
        }

        let settings = {
            doLoading: true,
            doAuth: true,
        }

        let token = User.getToken();

        System.debug(`Request [${config.method}][${config.url}]`);
        Object.assign(settings, _settings);

        if (data) {
            config.data = data;
            System.debug('Data: ', config.data);
        }

        if (params) {
            config.params = params;
            System.debug('Params: ', config.params);
        }
        
        if (settings.doAuth && token) config.headers = { 'Authorization': 'Bearer ' + token };
        if (settings.doLoading) Interface.loadingOn();

        return new Promise ((resolve, reject) => {
            axios(config)
                .then(response => {
                    System.debug(`Response [${response.status}][${response.statusText}]`);
                    System.debug('Data: ', response.data);

                    if (!response.data.success) {
                        reject(new ApiError(1, response.data.message || 'Unknown application error', response.data.message || []));
                    } else {
                        resolve(response.data.data || {});
                    }
                })
                .catch(error => {
                    if (error.response) {
                        System.debug(`Server error [${error.response.status}][${error.response.statusText}]`);
                        reject(new ApiError(error.response.status, error.response.statusText || 'Unknown server error'));
                    } else if (error.request) {
                        System.debug('Network error');
                        reject(new ApiError(2, 'Network error'));
                    } else {
                        System.debug('Other error');
                        reject(new ApiError(3, error.message || 'Unknown error'));
                    }                
                })
                .finally(() => {
                    if (settings.doLoading) Interface.loadingOff();
                });            
        })
    }

}


export default new Api();