<template>
<div>
    <div class="chart" ref="chart"></div>
</div>
</template>


<script>
import Monitoring from '@/classes/Monitoring'
import Charts from '@/classes/Charts'
import Dates from '@/classes/Dates'

export default {
    name: 'ChartUptime',
    // props: ['warning', 'alert', 'items', 'add'],
    props: ['warning', 'items'],
    data () {
        return {
            // Internal
            chart: false,
            warnRange: 0,
            valueAxis: false,
            // Data
        }
    },
    computed: {
        chartData () {
            let result = [...this.items];
            
            result.forEach(item => {
                item.responseCode = item.data.responseCode;
                item.dateHuman = Dates.human(item.when);
                item.loadTime = item.data.loadTime;
                item.contentSize = item.data.contentSize;
                item.color = Monitoring.colorByStatus(item.status, true);
            });

            return result;
        },
        maxLoadTime () {
            let result = 0;

            if (this.chartData.length > 0) {
                this.chartData.forEach(item => {
                    result = Math.max(result, item.loadTime)    
                });
                result *= 1.2;
            } else {
                result = 10;
            }

            return result;
        },
    },
    methods: {
        createChart () {
            [this.chart, this.warnRange, this.valueAxis] = Charts.createHistChart(
                this.$refs.chart,
                this.chartData,
                "loadTime",
                "Page load time (sec)",
                this.warning,
                "[bold font-size: 12px]{dateHuman}[/]\n\n[font-size: 12px]Response code: HTTP {responseCode}\nPage load time: {loadTime} sec\nContent size: {contentSize} bytes[/]",
            );
        },
        destroyChart () {
            Charts.destroyHistChart(this.chart);
        },
        newItems () {
            // this.valueAxis.max = this.maxLoadTime;
            this.chart.data = this.chartData;
        },
        // addItems () {
        //     this.chart.data.push(...this.add);
        //     // this.chart.addData(this.add);
        // },
        updateWarn () {
            this.warnRange.value = this.warning;
        },
    },
    mounted () {
        this.createChart();
    },
    beforeDestroy () {
        this.destroyChart();
    },    
    watch: {
        'items': 'newItems',
        // 'add': 'addItems',
        'warning': 'updateWarn',
    },
}
</script>


<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
