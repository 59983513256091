<template>
<div>
    <Monitor
        :iid="site.id"
        label="Domain Name Monitor"
        offq="Do you want to turn off website domain name monitoring?"
        code="domain"
        :options="optionsForm"
        :form="$refs.optionsForm"
        v-on:enabled="$emit('enabled', $event)"
        v-on:status="$emit('status', $event)"
        v-on:init="$emit('init')"
        v-on:options="resetOptions($event)"
    >
        <template v-slot:details="{monitor}">
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Registrar:</span>{{monitor.data.domainRegistrar || 'not available'}}</p>
            <p class="text-caption mb-0"><span class="font-weight-medium mr-2">Registrant:</span>{{monitor.data.domainRegistrant || 'not available'}}</p>
            <p class="text-caption mb-0" v-if="monitor.data.domainExpiry"><span class="font-weight-medium mr-2">Expires:</span>{{humandate(monitor.data.domainExpiry)}} ({{humandays(monitor.data.domainDaysExpiry)}})</p>
            <p class="text-caption mb-0 font-weight-medium error--text" v-else>Expiry date not available</p>
        </template>

        <template v-slot:options="{monitor}">
            <v-form ref="optionsForm">
                <v-subheader class="pl-0">Check frequency:</v-subheader>
                <v-slider
                    v-model="interval"
                    :max="intervalItems.length - 1"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    prepend-icon="mdi-timer"
                    :messages="intervalItems[interval] + planWarning"
                    :rules="[
                        (v) => validMinInt(v) || 'Interval error',
                    ]"
                ></v-slider>
                <v-subheader class="pl-0 mt-6">Notification frequency:</v-subheader>
                <v-select
                    v-model="frequency"
                    :items="frequencyItems"
                    item-text="label"
                    item-value="frequency"
                    dense
                    prepend-icon="mdi-bell-outline"
                ></v-select>
                <v-subheader class="pl-0 mt-6">Owner change alert:</v-subheader>
                <v-checkbox
                    v-model="checkreg"
                    hide-details="auto"
                    label="Alert if domain owner change is detected"
                    class="mt-0 mb-8"
                ></v-checkbox>
                <v-subheader class="pl-0 mt-6">Expiration warning:</v-subheader>
                <v-select
                    v-model="warning"
                    :items="warningItems"
                    item-text="label"
                    item-value="days"
                    dense
                    prepend-icon="mdi-alert-outline"
                    v-if="monitor.data.domainExpiry"
                ></v-select>
                <p class="pl-0 mt-0 error--text" v-else>Expiry date not available</p>
                <v-subheader class="pl-0 mt-6">Expiration alerts:</v-subheader>
                <v-select
                    v-model="alert"
                    :items="alertItems"
                    item-text="label"
                    item-value="days"
                    dense
                    prepend-icon="mdi-alert"
                    v-if="monitor.data.domainExpiry"
                ></v-select>
                <p class="pl-0 mt-0 error--text" v-else>Expiry date not available</p>
            </v-form>
        </template>

        <template v-slot:help>
            <p class="text-body-1 text-center">Domain Name Monitor</p>
            <p>
                When a user types the web address of your website in a browser, he does not get to your website immediately. First, the browser must find out it’s IP address. Browser uses a special network request to a DNS server, asking “what is the IP address of www.yourwebsite.com?”.
                When the response is received and the IP address of the destination server is known, only then the browser can connect to your website and show the webpage to a user.
            </p>
            <p>
                That means if the DNS service is not able to resolve your website's name then the user will not be able to open your website even if your website is up and running. DNS failures are one of the most common reasons for website unavailability. 
                There could be various technical issues, like DNS server malfunction, incorrect DNS configuration, expired domain name and more.
            </p>
            <p>
                Our Domain Name Monitor checks your website's domain name to make sure the IP address of your website is available and alerts you when there is a problem, so you could take immediate action and minimize the downtime of your website. 
                Our service will also warn you before your domain name is about to expire, or when there is a change in the ownership of your domain. This could prevent you from losing control of your domain if you forget to renew it or it is hijacked by a bad person.
            </p>
            <p>
                Monitor can be controlled with following settings:
            </p>
            <p class="mb-0 font-weight-bold">Check frequency</p>
            <p>How often shall we check your domain name.</p>
            <p class="mb-0 font-weight-bold">Notification frequency</p>
            <p>
                How often shall we send you alerts about discovered problems.
                <ul>
                    <li>Every check - We will send you notification every time we discover a problem even if this problem was already reported after the previous check.</li>
                    <li>Once per problem - We will send you notification once when a problem is first discovered or problem severity increases compared to the previous check.</li>
                    <li>Once in an hour / Once in a day - Notifications about the same problem will be sent no more often than the selected period. New notification may be sent if problem severity increases compared to the previous check.</li>
                    <li>Don’t send notifications - We will not send any notifications about this event.</li>
                </ul>
            </p>
            <p class="mb-0 font-weight-bold">Owner change alert</p>
            <p>Enable if you want us to alert you if there is a domain ownership change detected.</p>
            <p class="mb-0 font-weight-bold">Expiration warning</p>
            <p>When shall we send the first domain name expiration warning.</p>
            <p class="mb-0 font-weight-bold">Expiration alerts</p>
            <p>When shall we start sending everyday domain name expiration alerts.</p>
        </template>

    </Monitor>

</div>
</template>


<script>
import {mapState} from 'vuex'

import Monitoring from '@/classes/Monitoring'
import Dates from '@/classes/Dates'

import Monitor from '@/components/monitoring/Monitor'
import System from '@/classes/System'

export default {
    name: 'MonitorDomain',
    props: ['site'],
    components: {
        Monitor,
    },
    data () {
        return {
            // Options
            interval: 3,
            intervalItems: [
                'Every minute',
                'Every 5 minutes',
                'Every 15 minutes',
                'Every 30 minutes',
                'Every hour',
                'Every 4 hours',
                'Every 12 hours',
                'Once a day',
            ],
            intervals: {
                0:       1 * 60,
                1:       5 * 60,
                2:      15 * 60,
                3:      30 * 60,
                4:      60 * 60,
                5:  4 * 60 * 60,
                6: 12 * 60 * 60,
                7: 24 * 60 * 60,
            },
            frequency: 2,   
            frequencyItems: [
                {frequency: 1, label: "Every check"},
                {frequency: 2, label: "Once per problem"},
                {frequency: 3, label: "Once in an hour"},
                {frequency: 4, label: "Once in a day"},
                {frequency: 0, label: "Don't send notifications"},
            ],
            warning: 30,
            warningItems: [
                {days: 60, label: "2 months before expiry"},
                {days: 30, label: "1 month before expiry"},
                {days: 14, label: "2 weeks before expiry"},
                {days: 7,  label: "1 week before expiry"},
            ],
            alert: 7,   
            alertItems: [
                {days: 7, label: "1 week before expiry"},
                {days: 5, label: "5 days before expiry"},
                {days: 3, label: "3 days before expiry"},
                {days: 1, label: "1 day before expiry"},
            ],
            checkreg: true,
            // Other
            plan: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        optionsForm () {
            return {
                intervalCheck: this.intervals[this.interval],
                alertFreq: this.frequency,
                daysAlertFirst: this.warning,
                daysAlertMass: this.alert,
                checkRegChange: this.checkreg,
            }
        },
        planWarning () {
            if (this.plan && this.intervals[this.interval] < this.plan.limits.minInt) {
                return ' (not available in your subscription plan)'
            } else {
                return ''
            }
        },
    },
    methods: {
        init () {
            this.plan = System.getPlan(this.user.data.plan);
        },
        resetOptions (options) {
            this.interval = Object.keys(this.intervals).find(key => this.intervals[key] == options.intervalCheck) || 3;
            this.frequency = options.alertFreq;
            this.warning = options.daysAlertFirst;
            this.alert = options.daysAlertMass;
            this.checkreg = options.checkRegChange;
        },
        humandate (date) {
            return Dates.human(date);
        },
        humandays (days) {
            if (days == 1) {
                return `tomorrow`
            } else if (days == 0) {
                return `today`
            } else if (days < 0) {
                return `expired ${Math.abs(days)} days ago`
            } else {
                return `in ${days} days`
            }
        },
        color (status) {
            return Monitoring.colorByStatus(status);
        },
        validMinInt (v) {
            return this.intervals[v] >= this.plan.limits.minInt;
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        'site': 'init',
    },    
}
</script>


<style scoped>
</style>
