import merge from 'lodash/merge';

// Application default settings 

let settings = {
    // API URL
    // Type: String ''
    // apiBaseUrl: 'https://account.rankchart.org/api',
    apiBaseUrl: '/api',


    // Public assets URL base
    // Type: String ''
    pubBaseUrl: '/info',
 

    // Application URL base
    // Use if application is not served from the root
    // Type: String ''
    appBaseUrl: '/',
 

    // Use nice URLs without hash #
    // Requires setting on server side to redirect all requests to app file
    // Type: Boolean true/false
    useNiceUrl: true,


    // Support email
    // Type: String ''
    supportEmail: 's'+'upp'+'o'+'rt'+'@'+'ra'+'nkc'+'har'+'t.o'+'rg',

    
    // Console debug enabled
    // Type: Boolean
    debug: false,


    // reCAPTCHA site key
    // Type: String ''
    captchaSiteKey: '',

};

merge(settings, window.$settings);


export default settings;
