<template>
    <v-alert v-model="showAlert" text dismissible dense :type="type" :icon="icon">
        {{ alert.text }}
    </v-alert>
</template>


<script>
import { ALERT_TYPE } from '@/constants/interface'

export default {
    name: 'Alert',
    computed: {
        type () {
            if (this.alert.type == ALERT_TYPE.SUCCESS) {
                return 'success'
            } else if (this.alert.type == ALERT_TYPE.WARNING) {
                return 'warning'
            } else if (this.alert.type == ALERT_TYPE.ERROR) {
                return 'error'
            } else {
                return 'info'
            }
        },
        icon () {
            if (this.alert.type == ALERT_TYPE.SUCCESS) {
                return 'mdi-checkbox-marked-circle-outline'
            } else if (this.alert.type == ALERT_TYPE.WARNING) {
                return 'mdi-alert-outline'
            } else if (this.alert.type == ALERT_TYPE.ERROR) {
                return 'mdi-alert'
            } else {
                return 'mdi-information-outline'
            }
        },
        alert () {
            return this.$store.state.system.alert
        },
        showAlert: {
            get () {
                return this.alert.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/alertOff', false)
                }
            }
        },
    },
}
</script>


<style scoped>
</style>
