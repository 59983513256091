<template>
<!-- Main Menu -->
<v-list dense>
    <template v-for="item in MAIN_MENU">
        <!-- Header -->
        <v-subheader v-if="item.type === MENU_TYPE.HEAD" :key="item.id">{{ item.title }}</v-subheader>
        <!-- Divider -->
        <v-divider v-else-if="item.type === MENU_TYPE.SEPA" :key="item.id"></v-divider>
        <!-- Menu link -->
        <v-list-item v-else-if="item.type === MENU_TYPE.LINK" :key="item.id" :to="item.to" color="primary" link>
            <v-list-item-icon v-if="item.icon"><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
            <v-list-item-content :class="item.icon?'':'pl-14'">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- Group -->
        <v-list-group v-else-if="item.type === MENU_TYPE.GRP" :prepend-icon="item.icon" :key="item.id" :value="item.opened">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <template v-for="grpitem in item.items">
                <!-- Group link -->
                <v-list-item v-if="grpitem.type === MENU_TYPE.LINK" :key="grpitem.id" :to="grpitem.to" link>
                    <v-list-item-icon v-if="grpitem.icon"><v-icon>{{ grpitem.icon }}</v-icon></v-list-item-icon>
                    <v-list-item-content :class="grpitem.icon?'':'pl-14'">
                        <v-list-item-title>{{ grpitem.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- Subgroup -->
                <v-list-group v-else-if="grpitem.type === MENU_TYPE.GRP" no-action sub-group :key="grpitem.id" :value="grpitem.opened">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ grpitem.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <!-- Subgroup link -->
                    <template v-for="sgrpitem in grpitem.items">
                        <v-list-item v-if="sgrpitem.type === MENU_TYPE.LINK" :key="sgrpitem.id" :to="sgrpitem.to" link>
                            <v-list-item-title>{{ sgrpitem.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list-group>
            </template>
        </v-list-group>
    </template>
</v-list>
</template>


<script>
// import { mapState } from 'vuex'

import { MENU_TYPE, MAIN_MENU } from '@/constants/interface';

export default {
    name: 'Menu',
    data () {
        return {
            MENU_TYPE,
            MAIN_MENU,
        }
    },
    // computed: {
    //     ...mapState({
    //         menu: state => state.user.menu,
    //     }),
    // },

}
</script>


<style scoped>
</style>
