var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-center align-center content-wrapper mt-5 mt-sm-16"},[_c('v-sheet',{attrs:{"elevation":"1","rounded":"lg","width":"400"}},[_c('div',{staticClass:"ma-8 pt-10"},[_c('v-form',{ref:"registerForm"},[_c('v-text-field',{ref:"email",attrs:{"label":"Email","hint":"Email will be used for login","type":"email","counter":"100","autocomplete":"username","append-icon":"mdi-account","rules":[
                            function (v) { return !!v || 'Email is required'; },
                            function (v) { return /.+@.+/.test(v) || 'Email must be valid'; },    
                            function (v) { return v.length <= 100 || 'Email too long'; } ],"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{ref:"name",attrs:{"label":"Your Name","hint":"Tell us how to call you?","counter":"100","append-icon":"mdi-comment-account","rules":[
                            function (v) { return !!v || 'Enter your name'; },
                            function (v) { return v.length <= 100 || 'Name too long'; } ],"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Password","hint":"Choose your password","type":_vm.show ? 'text' : 'password',"counter":"100","autocomplete":"new-password","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                            function (v) { return !!v || 'Enter your password'; },
                            function (v) { return v.length <= 100 || 'Password too long'; } ],"required":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{ref:"password2",attrs:{"disabled":_vm.show,"label":"Password Confirmation","hint":"Confirm your password","type":"password","counter":"100","autocomplete":"new-password","append-icon":"mdi-eye-check","rules":[function () { return (_vm.show || _vm.password == _vm.password2) || 'Passwords do not match'; }],"required":""},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('v-checkbox',{attrs:{"rules":[function () { return (_vm.agree) || 'You must agree with service terms'; }]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Accept service"),_c('router-link',{staticClass:"mx-1",attrs:{"to":{name: 'terms', query: {back: 'register'}}}},[_vm._v("terms and privacy")]),_vm._v(" policy ")]},proxy:true}]),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}}),_c('v-btn',{staticClass:"mt-6",attrs:{"type":"submit","color":"primary","block":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.register()}}},[_vm._v("Create New Account")])],1),_c('p',{staticClass:"mt-5"},[_c('router-link',{staticClass:"text-body-2",attrs:{"to":{ name: 'login' }}},[_vm._v("Login with existing account")])],1)],1)])],1),_c('div',{staticClass:"text-center mt-10"},[_c('p',{staticClass:"mt-5 text-caption"},[_vm._v("By using this service you agree with "),_c('router-link',{attrs:{"to":{name: 'terms', query: {back: 'register'}}}},[_vm._v("Terms and Privacy")]),_vm._v(" policy. This website is using local storage to save the unsensitive session data.")],1),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mt-5 text-caption"},[_vm._v("This site is protected by reCAPTCHA and the Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://policies.google.com/terms","target":"_blank"}},[_vm._v("Terms of Service")]),_vm._v(" apply.")])}]

export { render, staticRenderFns }