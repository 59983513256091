import System from '@/classes/System'

class Scheduler {

// Timer functions

    at (_when, fn, options = {}) {
        let now = Date.now();
        let when = Date.parse(_when);
        let delta = (when - now);
        
        if (isNaN(delta)) {
            System.debug('Scheduler invalid time: ', _when);
            return false;
        }

        if (delta < 0) delta = 0;
        if (options.min && options.min > delta) delta = options.min;
        if (options.max && options.max < delta) delta = options.max;
        if (options.add) delta += options.add;

        System.debug(`Scheduler after: ${delta} msec (${delta/1000} sec, ${delta/1000/60} min)`);

        return setTimeout(fn, delta);
    }

    every (ms, fn) {
        System.debug(`Scheduler every: ${ms} msec (${ms/1000} sec, ${ms/1000/60} min)`);
        return setInterval(fn, ms);
    }

    stop (timer) {
        System.debug(`Scheduler stop: ${timer}`);
        clearTimeout(timer);
        return false;
    }
}


export default new Scheduler();