<template>
<div>
    <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
        <v-sheet elevation="1" rounded="lg" width="400">
            <div class="ma-8 pt-10">
                <v-form ref="remindForm">
                    <v-text-field 
                        label="Your Email"
                        hint="Email you have used for login"
                        ref="email"
                        type="email"
                        counter="100"
                        v-model="email"
                        autocomplete="username"
                        append-icon="mdi-account"
                        :rules="[
                            (v) => !!v || 'Email is required',
                            (v) => v.length <= 100 || 'Email too long',
                            (v) => /.+@.+/.test(v) || 'Email must be valid',
                        ]"
                        required
                    ></v-text-field>
                    <v-btn
                        class="mt-6" 
                        type="submit"
                        color="primary" 
                        block
                        :loading="loading"
                        @click.prevent="remind()"
                    >Reset Password</v-btn>
                </v-form>
                <p class="mt-5"><router-link :to="{ name: 'login' }" class="text-body-2">Login with existing account</router-link></p>
            </div>
        </v-sheet>
    </div>
    <div class="text-center mt-10">
        <p class="mt-5 text-caption">This site is protected by reCAPTCHA and the Google<br/><a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
    </div>
</div>
</template>


<script>
import Interface from "@/classes/Interface";
import User from '@/classes/User'
// import System from '@/classes/System'

export default {
    name: 'Remindpwd',
    data () {
        return {
            email: '',
            token: '',
            loading: false,
        }
    },
    methods: {
        async remind () {
            if (this.$refs.remindForm.validate()) {
                Interface.alertOff();
                this.loading = true;

                try {
                    await this.$recaptchaLoaded();
                    this.token = await this.$recaptcha('remind');
                } catch (error) {
                    this.loading = false;
                    this.token = false;
                    Interface.alertError('reCAPTCHA validation failed');
                    return false;
                }

                return User.remindpwd(this.remindForm)
                    .then(() => {
                        this.email = '';
                        Interface.alertSuccess('Check your email to complete the password reset...');
                        // System.redirectToLogin();
                    })
                    .catch(error => {
                        Interface.alertError(error.message||'Operation failed');
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$refs.remindForm.resetValidation();
                    })
            }
        },
    },
    computed: {
        remindForm () {
            return {
                email: this.email,
                token: this.token,
            }
        }
    },
    mounted () {
        Interface.alertOff();
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
