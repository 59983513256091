const SITE_STATUS = {
    UNKNOWN: 0,
    OK:      1,
    WARNING: 2,
    ERROR:   3,
}

const PLAN_TYPE = {
    PERSONAL: 1,
    PRO:      2,
    ENT:      3,
}

const LOGIN_TYPE = {
    NONE:   0,
    LOCAL:  1,
    GOOGLE: 2,
    FB:     3,
}

export { SITE_STATUS, PLAN_TYPE, LOGIN_TYPE };
