import sanitizeHtml from 'sanitize-html';

import Public from '@/services/public'

const sanitizeOptions = {
    allowedTags: [
        "h1", "h2", "h3", "h4", "h5", "h6", "blockquote", "div", "hr", "li", "ol", "p", 
        "pre", "ul", "a", "b", "br", "i", "small", "span", "strong", "sub", "sup", 
        "caption", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img",
    ],
    allowedAttributes: {
        a:   ['href', 'name', 'target'],
        img: ['src'],
        '*': ['class', 'style'],
    },
};

class Info {

// Helper functions

// API functions

    getTerms () {
        return Public.html(`/terms.html`)
            .then(data => {
                return sanitizeHtml(data, sanitizeOptions);
            });
    }

}

export default new Info();