const MENU_TYPE = {
    LINK: 1,
    SEPA: 2,
    HEAD: 3,
    GRP:  4,
}

const NOTIF_TYPE = {
    INFO:    1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR:   4,
}

const ALERT_TYPE = {
    INFO:    1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR:   4,
}

const SNACK_TYPE = {
    INFO:    1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR:   4,
}

const MAIN_MENU = [
    { id: 1, type: MENU_TYPE.HEAD, title: 'Websites'},
    { id: 2, type: MENU_TYPE.LINK, title: 'My Websites', icon: 'mdi-server', to: {name: 'websites'}},
    { id: 3, type: MENU_TYPE.LINK, title: 'Dashboard', icon: 'mdi-view-dashboard', to: {name: 'dashboard'}},
    { id: 4, type: MENU_TYPE.LINK, title: 'Event Log', icon: 'mdi-bell-ring', to: {name: 'events'}},
    { id: 5, type: MENU_TYPE.LINK, title: 'Reports', icon: 'mdi-file-chart', to: {name: 'reports'}},
    { id: 6, type: MENU_TYPE.HEAD, title: 'Settings'},
    { id: 7, type: MENU_TYPE.LINK, title: 'My Account', icon: 'mdi-cog', to: {name: 'account'}},
    { id: 8, type: MENU_TYPE.LINK, title: 'Support', icon: 'mdi-at', to: {name: 'support'}},
]

export { MENU_TYPE, NOTIF_TYPE, ALERT_TYPE, SNACK_TYPE, MAIN_MENU };
