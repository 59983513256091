import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/apexcharts';
import './plugins/recaptcha';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// import System from "./classes/System";

// loadInitialData();

Vue.config.productionTip = false

window.$app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    // computed: {
    //     user () {
    //         return !!store.state.user.data.email;
    //     },
    // },
    // created () {
    //     console.log('Main created');
    // },
}).$mount('#app');

// vm.$watch('user', function(newValue, oldValue) {
//     console.log('new: ', newValue, 'old: ', oldValue);
// });

// async function loadInitialData() {
//     console.log('loadInitialData start');
//     let result = await System.init();
//     console.log('loadInitialData end');
//     return result;
// }