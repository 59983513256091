<template>
<div>
</div>
</template>


<script>
import Interface from "@/classes/Interface";
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'Remindpwd',
    data () {
        return {
            regId: false,
            code: false,
            loading: false,
        }
    },
    computed: {
        confirmForm () {
            return {
                regId: this.regId,
                code: this.code,
            }
        }
    },
    methods: {
        async confirm () {
            if (!this.regId || !this.code) {
                Interface.alertError('Inavlid email confirmation link');
                return false;
            }

            return User.confirm(this.confirmForm)
                .then(() => {
                    Interface.alertSuccess('User email successfully confirmed, you may login with your credentials');
                    System.redirectToLogin();
                })
                .catch(error => {
                    Interface.alertError(error.message||'Confirmation request failed');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted () {
        Interface.alertOff();
        this.regId = this.$route.params.regId;
        this.code = this.$route.params.code;
        this.confirm();
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
