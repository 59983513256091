import goTo from 'vuetify/es5/services/goto'
import store from '@/store'

import { ALERT_TYPE, SNACK_TYPE } from '@/constants/interface' 

class Interface {

    alertInfo (text) {
        store.commit('system/alertOn', {type: ALERT_TYPE.INFO, text});
        goTo(0);
    }

    alertSuccess (text) {
        store.commit('system/alertOn', {type: ALERT_TYPE.SUCCESS, text});
        goTo(0);
    }

    alertWarning (text) {
        store.commit('system/alertOn', {type: ALERT_TYPE.WARNING, text});
        goTo(0);
    }

    alertError (text) {
        store.commit('system/alertOn', {type: ALERT_TYPE.ERROR, text});
        goTo(0);
    }

    alertOff () {
        store.commit('system/alertOff');
    }

    snackInfo (text) {
        store.commit('system/snackOn', {type: SNACK_TYPE.INFO, text});
    }

    snackSuccess (text) {
        store.commit('system/snackOn', {type: SNACK_TYPE.SUCCESS, text});
    }

    snackWarning (text) {
        store.commit('system/snackOn', {type: SNACK_TYPE.WARNING, text});
    }

    snackError (text) {
        store.commit('system/snackOn', {type: SNACK_TYPE.ERROR, text});
    }

    snackOff () {
        store.commit('system/snackOff');
    }

    loadingOn () {
        store.commit('system/loadingOn');
    }

    loadingOff () {
        store.commit('system/loadingOff');
    }

    planErrOn () {
        store.commit('system/setPlanError', true);
    }

}


export default new Interface();