<template>
<div>
    <div class="d-flex justify-center align-center content-wrapper mt-5 mt-sm-16">
        <v-sheet elevation="1" rounded="lg" width="400">
            <div class="ma-8 pt-10">
                <v-form ref="resetForm">
                    <v-text-field 
                        label="New Password" 
                        hint="Choose your new password"
                        :type="show ? 'text' : 'password'"
                        v-model="password"
                        counter="100"
                        autocomplete="new-password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                            (v) => !!v || 'Enter your password',
                            (v) => v.length <= 100 || 'Password too long',
                        ]"
                        required
                        @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field 
                        :disabled="show"
                        ref="password2"
                        label="Password Confirmation" 
                        hint="Confirm your new password"
                        type="password"
                        counter="100"
                        autocomplete="new-password"
                        v-model="password2"
                        append-icon="mdi-eye-check"
                        :rules="[() => (show || password == password2) || 'Passwords do not match']"
                        required
                    ></v-text-field>
                    <v-btn
                        class="mt-6" 
                        type="submit"
                        color="primary" 
                        block
                        :loading="loading"
                        @click.prevent="reset()"
                    >Reset Password</v-btn>
                </v-form>
                <p class="mt-5"><router-link :to="{ name: 'login' }" class="text-body-2">Login with existing account</router-link></p>
            </div>
        </v-sheet>
    </div>
    <div class="text-center mt-10">
        <p class="mt-5 text-caption">This site is protected by reCAPTCHA and the Google<br/><a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
    </div>
</div>
</template>


<script>
import Interface from "@/classes/Interface";
import User from '@/classes/User'
import System from '@/classes/System'

export default {
    name: 'Remindpwd',
    data () {
        return {
            password: '',
            password2: '',
            token: '',
            ticket: '',
            show: false,
            loading: false,
        }
    },
    methods: {
        async reset () {
            if (!this.ticket) {
                Interface.alertError('Inavlid password reset link');
                return false;
            }

            if (this.$refs.resetForm.validate()) {
                Interface.alertOff();
                this.loading = true;

                try {
                    await this.$recaptchaLoaded();
                    this.token = await this.$recaptcha('reset');
                } catch (error) {
                    this.loading = false;
                    this.token = false;
                    Interface.alertError('reCAPTCHA validation failed');
                    return false;
                }

                return User.resetpwd(this.resetForm)
                    .then(() => {
                        Interface.alertSuccess('Password changed, login using your new password');
                        // System.redirectToSaved('/');
                        System.redirectToLogin();
                    })
                    .catch(error => {
                        this.$refs.resetForm.resetValidation()
                        Interface.alertError(error.message||'Password reset failed');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        resetForm () {
            return {
                password: this.password,
                token: this.token,
                ticket: this.ticket,
            }
        }
    },
    watch: {
        show (newv) {
            if (newv) {
                this.password2 = '';
                this.$refs.password2.resetValidation();
            }
        },
    },
    mounted () {
        Interface.alertOff();

        if (this.$route.query.ticket) {
            this.ticket = this.$route.query.ticket;
        }        
    },
}
</script>


<style scoped>
.content-wrapper {
    min-height: 100%;
}
</style>
