import store from '@/store'
import Sites from '@/classes/Sites'
import Encrypt from '@/classes/Encrypt'
import Scheduler from '@/classes/Scheduler'
import System from '@/classes/System'

import {LOGIN_TYPE} from '@/constants/common'

// import Api from '@/services/api'
// import Api from '@/services/api-mock'
import Api from '@/services/api-wrapper'

class User {

    constructor () {
        this.timer = false;
    }

// Helper functions

    initials (string) {
        let names = string.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        
        return initials;
    }

// API functions

    login (data) {
        return Api.post('/user/login', data, false, {doAuth: false})
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    loginFB (data) {
        return Api.post('/user/login/facebook', data, false, {doAuth: false})
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    loginG (data) {
        return Api.post('/user/login/google', data, false, {doAuth: false})
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    logout () {
        Api.post('/secure/user/logout');
        this.setLogout();
        return; 
    }

    register (data) {
        return Api.post('/user/register', data, false, {doAuth: false});
    }

    remindpwd (data) {
        return Api.post('/user/remindpwd', data, false, {doAuth: false});
    }

    resetpwd (data) {
        return Api.post('/user/resetpwd', data, false, {doAuth: false});
    }

    confirm (data) {
        return Api.post('/user/confirm', data, false, {doAuth: false});
    }

    data (data) {
        return Api.post('/secure/user/set/data', data)
            .then(data => {
                store.commit('user/setData', data);
                return data;
            });
    }

    pass (cpass, npass) {
        return Api.post('/secure/user/set/pwd', {cpass, npass})
    }

// Data functions

    setLogin (user) {
        if (user.sites) Sites.setSites(user.sites);
        store.commit('user/setData', user.data);
        store.commit('user/setSession', user.session);
        this.setToken(user.session.token, user.session.save);
        this.timer = Scheduler.every(60000, this.runRefresh);
    }

    setLogout () {
        this.timer = Scheduler.stop(this.timer);
        if (store.state.user.session.type == LOGIN_TYPE.FB) {
            window.FB.logout();
        }
        store.commit('user/resetState');
        localStorage.removeItem('to_route');
        this.clearToken();
    }

    runRefresh () {
        System.debug(`User refresh...`);
        Sites.get();
    }

// Token functions

    setToken (token, persistent = false) {
        this.clearToken();

        if (persistent) {
            localStorage.setItem('token', Encrypt.enc(token));
        } else {
            sessionStorage.setItem('token', Encrypt.enc(token));
        }
    }

    getToken () {
        let token = sessionStorage.getItem('token') || localStorage.getItem('token') || false;
        return Encrypt.dec(token);
    }

    clearToken () {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
    }

}


export default new User();