<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        
        <div class="mb-10">
            <p class="text-h5 font-weight-light text-center mb-0">Monitoring Dashboard</p>
            <p class="text-caption font-weight-light text--secondary text-center mb-1">Realtime all in one dashboard with statistics for each monitor</p>
        </div>

        <div v-if="allUntracked">
            <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="mb-10"
                icon="mdi-comment-alert"
            >
                <p class="text-subtitle-2 warning--text">Monitoring is not configured</p>
                <p class="text-caption grey--text">To enable a monitoring, open My Websites list on the right, expand any website and select:</p>
                <p class="text-caption grey--text ml-sm-3"><v-icon>mdi-server-network</v-icon> Domain and server level monitoring</p>
                <p class="text-caption grey--text ml-sm-3"><v-icon>mdi-file-outline</v-icon> Page level monitoring for each added page</p>
            </v-alert>
        </div>

        <div v-if="countSites" class="d-flex flex-wrap justify-space-around align-stretch">
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartSites.chartOptions" :series="chartSites.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">{{countSites}} websites</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartPages.chartOptions" :series="chartPages.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">{{countPages}} pages</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartPing.chartOptions" :series="chartPing.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">Ping</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartSsl.chartOptions" :series="chartSsl.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">SSL</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartDomain.chartOptions" :series="chartDomain.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">Domains</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartHtml.chartOptions" :series="chartHtml.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">HTML Validation</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartMobile.chartOptions" :series="chartMobile.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">Mobile Performance</p>
                </div>
            </div>
            <div class="pie-cell">
                <div class="pie">
                    <apexchart type="donut" :options="chartDesktop.chartOptions" :series="chartDesktop.series"></apexchart>
                </div>
                <div class="text-center mb-4">
                    <p class="text-overline">Desktop Performance</p>
                </div>
            </div>
        </div>

    </v-sheet>
</div>
</template>


<script>
import { mapState } from 'vuex'

import Monitoring from '@/classes/Monitoring'
import Interface from '@/classes/Interface'
import Charts from '@/classes/Charts'

let chartOptions = {
    labels: ['Untracked', 'OK', 'Warnings', 'Errors'],
    legend: {
        show: false
    },
    dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
            return opts.w.config.series[opts.seriesIndex];
        },
    },
    colors: ['#BDBDBD', '#00E676', '#FFC107', '#F44336'],
    plotOptions: {
        pie: {
            donut: {
                size: '50%',
            },
            expandOnClick: false,
        }
    }    
}


export default {
    name: 'Dashboard',
    data () {
        return {
            url: '',
            loading: false,
            stats: false,
            chartSites: {
                series: [],
                chartOptions,
            },
            chartPages: {
                series: [],
                chartOptions,
            },
            chartPing: {
                series: [],
                chartOptions,
            },
            chartSsl: {
                series: [],
                chartOptions,
            },
            chartDomain: {
                series: [],
                chartOptions,
            },
            chartHtml: {
                series: [],
                chartOptions,
            },
            chartMobile: {
                series: [],
                chartOptions,
            },
            chartDesktop: {
                series: [],
                chartOptions,
            },
        }
    },
    computed: {
        ...mapState({
            sites: state => state.user.sites,
        }),
        countSites () {
            return this.stats.all || 0;
        },
        countPages () {
            return this.stats.allpages || 0;
        },
        allUntracked () {
            return (this.chartSites.series[0] && !this.chartSites.series[1] && !this.chartSites.series[2] && !this.chartSites.series[3])
        },
    },
    methods: {
        init () {
            this.stats = Monitoring.statsSites(this.sites);
            this.initCharts();
        },
        initCharts () {
            this.chartSites.series = Charts.getSitesStatusSeries(this.stats);
            this.chartPages.series = Charts.getPagesStatusSeries(this.stats);
            this.chartPing.series = Charts.getSitesPingSeries(this.stats);
            this.chartSsl.series = Charts.getSitesSslSeries(this.stats);
            this.chartDomain.series = Charts.getSitesDomainSeries(this.stats);
            this.chartHtml.series = Charts.getPagesHtmlSeries(this.stats);
            this.chartMobile.series = Charts.getPagesMobileSeries(this.stats);
            this.chartDesktop.series = Charts.getPagesDesktopSeries(this.stats);
        },
    },
    mounted () {
        Interface.alertOff();
        this.init();
    },
    watch: {
        'sites': 'init',
    },
}
</script>


<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
.pie-cell {
    width: 200px;
}
.pie {
    width: 200px;
    height: 150px;
}
</style>